import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MomSysCustomerSupplyReturnDialog from "../dialogTypes/MomSysCustomerSupplyReturnDialog";

function MomSysCustomerSupplyReturnRegisterButton(props) {
    const { t } = useTranslation();

    const { id, schema, onSuccess, createButtonName, customizeErrorMsgCallback, refresh, ...others } = props;

    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onCreateSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <CreateRecordButtonLayout
                id={id + "-create-button"}
                onClick={onOpen}
                createButtonName={t('materialReturn')}
                {...others}
            />
            <MomSysCustomerSupplyReturnDialog
                id={id + "-create-dialog"}
                mode="create"
                formId={id + "create-form"}
                title={schema ? t(`dialog.title.${schema.name}.create`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </>
    );
}

export default MomSysCustomerSupplyReturnRegisterButton;
