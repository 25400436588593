import { useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import CrudScreenActionItemLayoutByAccessType from "../screenTypes/layout/CrudScreenActionItemLayoutByAccessType";
import SaveToExcelButton from "../input/SaveToExcelButton";
import { CellType } from '@grapecity/wijmo.grid';

function MomSysFailLotTerminateHistory ( props ) {

    const { accessType } = props;

    const { t } = useTranslation();

    const id = "MomSysFailLotTerminateHistory";
    const view = "MomSysVwFailLotTerminateHistory";

    const [ grid, setGrid ] = useState();
    const [ isEmpty, setIsEmpty ] = useState();
    const [ data, setData ] = useState();

    const onInitialized = ( grid ) => {

        setGrid ( grid );

    }

    const onLoadData = ( data ) => {

        setData(data);

        setIsEmpty(data.length < 1 ? true : false)

    }

    const exportFormatItem = (args) => {

        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {

            switch (panel.columns[col].binding) {

                case "qty":
                case "price":
                case "amount":

                    if(xlsxCell.value === '') {

                        xlsxCell.value = 0;

                        break;

                    }

                    const cellValue = data[row][panel.columns[col].binding];

                    if(Number.isInteger(cellValue)) {

                        xlsxCell.style.format = "#,##0"

                    } else {

                        xlsxCell.style.format = "#,##0.####"

                    }

                    break;

                default:

                    break;

            }

        }

    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            <>
                <CrudScreenActionItemLayoutByAccessType accessType = {accessType}>
                    <SaveToExcelButton
                        id={id}
                        grid={grid}
                        view={view}
                        color="primary"
                        disabled={isEmpty}
                        exportFormatItem={exportFormatItem}
                    />
                </CrudScreenActionItemLayoutByAccessType>
            </>
        )

    }

    return (
        <BasicCrudScreenType 
            id={id} 
            view={view} 
            headerTitle = {t("term.failLotTerminateHistory")}
            noCreate 
            noEdit 
            noDelete 
            noExcel 
            onLoadData={onLoadData}
            onInitialized = {onInitialized}
            onAppendButtonInFrontOfSearch = {onAppendButtonInFrontOfSearch}
        />
    )

}

export default MomSysFailLotTerminateHistory;
