/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import notificationState from "../../../states/notificationState";
import apiClient from "../../../lib/common/apiClient";
import parseS3FileName from "../../../lib/utils/parseS3FileName";

// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridFileDownloadColumn ( props ) {

    const { t } = useTranslation();

    const { schema, ...others } = props;

    const [, setNotification] = useRecoilState(notificationState);
    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = ( reason ) => {

        notifyFailure(
            t ( "success.fileDownloadFailure", { reason } )
        );

    };
    
    const onClick = ( ctx ) => {

        let url;

        if ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' ) {

            url = ctx.item[schema.name];

        } else {

            url = `http://localhost:8080${ctx.item[schema.name]}`;

        }

        let targetFileName = parseS3FileName(ctx.item[schema.name]);

        if ( schema.grid.targetFileName ) {

            targetFileName = ctx.item[schema.grid.targetFileName];

        }

        apiClient
            .get ( url, { responseType: "blob" } )
            .then ( ( result ) => {

                const blob = new Blob ( [result.data] );
                const link = document.createElement ( "a" );

                link.href = window.URL.createObjectURL ( blob );
                link.download = targetFileName;
                link.click ();

                window.URL.revokeObjectURL ( link.href );

            } )
            .catch ( async ( reason ) => {
                const blob = reason.response.data
                const msg = await blob.text ();
                onApiFailure ( msg )
            } )


    };

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {
            
            if ( ctx.item[schema.name] && ctx.item[schema.name][0] === '/') {

                return (
                    <>

                        <span onClick={ () => onClick ( ctx )}>
                                                        
                            <IconButton size="small" sx={{p: 0, m: 0}}>

                                <Icon>download</Icon>

                            </IconButton>

                        </span>

                    </>
                );

            }

        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridFileDownloadColumn;

