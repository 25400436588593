import { useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import defineConf from "../../config/defineConf";
import apiClient from "../../lib/common/apiClient";

function DeliveryStateChangeButton ( props ) {

    const { t } = useTranslation();

    const { id, schema, grid, title, refresh, onSuccess, onFailure, ...others } = props;

    const [ open, setOpen ] = useState(false);

    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        
        setConfirmMsg (
            t("dialog.msg.MomSysUglOrderBuyer.confirm.delivery", {
            orderBuyerId: `${grid.selectedItems[0].orderBuyerId}`,
            orderBuyerName: `${grid.selectedItems[0].orderBuyerName}`,
        })
    );
        setConfirmData ( grid.selectedItems[0]);

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onOk = () => {
        
        let form = new FormData();
        let json = {};
        
        if([defineConf.deliveryState.NO_DELIVERY,defineConf.deliveryState.PARTIAL_DELIVERY].includes(grid.selectedItems[0]?.deliveryState)) {

            json.deliveryState = defineConf.deliveryState.COMPLETE_DELIVERY;
            
        } else {

            json.deliveryState = defineConf.deliveryState.NO_DELIVERY;

        }
        
        form.append("json", JSON.stringify(json));
        apiClient
            .patch(`/api/MomSysUglOrderBuyerMst/${grid.selectedItems[0].orderBuyerId}`,form, {
            })
            .then((result) => {
                if (refresh) {
                    refresh.refresh();
                }
                onSuccess(result.data);
            })
            .catch((reason) => {
                onFailure(t("error.updateFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    }
    return (

        <>
            <CreateRecordButtonLayout 
                id={id+"confirm-button"} 
                onClick={onOpen} 
                createButtonName = {[defineConf.deliveryState.NO_DELIVERY,defineConf.deliveryState.PARTIAL_DELIVERY].includes(grid.selectedItems[0]?.deliveryState) ? "deliveryComplete" : "deliveryCancel"} 
                {...others}  
            />
            <ConfirmDialog
                id={id+"-delivery-confirm-dialog"}
                title={ schema ? t ( `dialog.title.${schema.name}.deliveryConfirm` ) : "" }
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>

    );
}

export default DeliveryStateChangeButton;

