import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import OrderPurchaseInputDialog from "../dialogTypes/OrderPurchaseInputDialog";

function OrderPurchaseInputButton(props) {
  const { t } = useTranslation();

  const {
    id,
    schema,
    onSuccess,
    createButtonName,
    customizeErrorMsgCallback,
    modifyUrlCallback,
    onSelectMst,
    refresh,
    ...others
  } = props;

  const [open, setOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCreateSuccess = (msg) => {
    if (onSuccess) {
      onSuccess(msg);
    }

    if(refresh) {
      refresh.refresh ();
  }
  };

  return (
    <>
      <CreateRecordButtonLayout
        id={id + "-create-button"}
        onClick={onOpen}
        createButtonName={createButtonName}
        {...others}
      />
      <OrderPurchaseInputDialog
        id={id + "-create-dialog"}
        formId={id + "-create-form"}
        schema = {schema}
        title={t(`dialog.title.OrderPurchaseInputMst.create`)}
        initialData={undefined}
        open={open}
        onClose={onClose}
        onSuccess={onCreateSuccess}
        onSelectMst={onSelectMst}
        
      />
    </>
  );
}

export default OrderPurchaseInputButton;
