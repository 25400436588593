import { useTranslation } from "react-i18next";
import { useState } from "react";
import apiClient from "../../lib/common/apiClient";

import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import ConfirmDialog from "../dialogTypes/ConfirmDialog";

function MomSysCustomerSupplyReturnCancelButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, refresh, makeItemName, onSuccess, onFailure, onPartialFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setOpen(true);

        let data = grid.selectedItems[0];
        setConfirmMsg(t("dialog.msg.confirm.customerSupplyReturnCancel"));
        setConfirmData(data);

        setOpen(true);
    };

    const onShipRecordCancelSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOk = (data) => {
        let url = `/api/${schema.name}/cancel`;

        let json = {};
        let apiData = { ...data };

        let form = new FormData();

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("json", JSON.stringify(json));
        apiClient
            .post(url, form)
            .then((result) => {
                onShipRecordCancelSuccess(result.data);
            })
            .catch((reason) => {
                onFailure(t("error.deleteFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    };

    return (
        <>
            <DeleteRecordButtonLayout id={id + "delete-button"} title={t('buttonName.customerSupplyReturnCancel')} onClick={onOpen} {...others} />
            <ConfirmDialog
                id={id + "-delete-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.delete`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>
    );
}

export default MomSysCustomerSupplyReturnCancelButton;
