/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import { cloneDeep } from "lodash";
import StockItem from "../screens/StockItem";
import StockItemLotNo from "../screens/StockItemLotNo";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function ExceptionOutDialog (props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, ...others
    } = props;

    const  classes = useStyles ();
    const {t} = useTranslation ();

    const [selectStockItem, setSelectStockItem] = useState();
    const [selectLotNo, setSelectLotNo] = useState();
    const [modifySchema, setModifySchema] = useState();
    const [instance, setInstance] = useState ();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const [attachedFilesFormData, setAttachedFilesFormData] = useState({});
  
    useEffect(() => {
        let modifiedSchema = cloneDeep(schema);

        // if ( modifiedSchema.columns.findIndex(o => o.name === 'attachedFiles') < 0) {

        //     modifiedSchema.columns.push( 
        //         {
        //         name: "attachedFiles",
        //         maxFiles : 10,
        //         formItemType: 'file',
        //         formOrder: 1000,
        //         formGroup : 1,
        //         width : '100%'
        //     })
        // }
        
        if (modifiedSchema) {
            for (const column of modifiedSchema.columns) {
                switch (column.name) {
                    case "lotNo":
                        column.hideInForm = false;
                        break;

                    case "jaHo":
                        column.hideInForm = true;
                        break;

                    case "manufacturedDate":
                        column.hideInForm = true;
                        break;

                    case "expirationDate":
                        column.hideInForm = true;
                        break;

                    case "qcResult":
                        column.required = false;
                        column.hideInForm = true;
                        break;

                    case "wareHouseCode":
                        column.hideInForm = true;
                        break;

                    case "defectiveCode":
                        column.hideInForm = true;
                        // column.require = false;
                        break;

                    case "inPrice":
                        column.hideInForm = true;
                        break;

                    case "inAmount":
                        column.hideInForm = true;
                        break;

                    default:
                        break;
                }
            }

            setModifySchema(modifiedSchema);
        }
    }, [schema]);
    
    useEffect(() => {
        if(selectStockItem) {
            setFormData({
                materialCode: selectStockItem.materialCode,
                materialCodeVer: selectStockItem.materialCodeVer,
                lotNo: selectLotNo.lotNo,
                materialName: selectStockItem.materialName,
                inoutDate: new Date()
            })
        }
    }, [selectLotNo]);

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning (msg);
                return false;
            }
        }
        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        if (onSuccess) {
            onSuccess (t("success.insertSuccess", {table: "MomSysMaterialByCompany"}));
        }

        setFormData ((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        setKeepOpen ((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout (onSuccess(result.data), 0);
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure (t(reason.response.data));
    };

    const onOk = () => {
        save(modifySchema, formData);
    }

    function fileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType === "file") {
           return key;
        }
    }
    
    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
           return key;
        }
    }
    
    function notUndefined(apiData, key) {
        if(apiData !== undefined) {
            return key;
        }
    }

    function save(schema, formData) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        if(!formData.qty || formData.qty < 0) {
            nofifyWarning(t("warning.failMessageQtyIsZero"));
            return;
        }

        // build base api url
        let url = "/api/ExceptionInOut";
        let apiData = { ...formData };
        let form = new FormData ();
        let json = {};
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema && !schema.columnMap) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        // 파일정보 인 경우 form 추가
        const fileKeys = keys.filter(key => fileKey(schema.columnMap, key));
        for(const key of fileKeys) {
            deleted[key] = apiData[key].deletedFiles;
            for(const file of apiData[key].activeFiles) {
                if(file.type !== "url") {
                    form.append(key, file.file);
                }
            }
        }

        // 파일정보가 아닌경우 json 추가
        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);

        json['type'] = 'out'
        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(json));

        
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    const onCancel = () => {
        setFormData ((formData) => {
            let newFormData = {
                ...formData,
                qty: 0,
                description: undefined,
                companyCode: undefined,
                inoutDate: undefined,
                attachedFiles: []
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>
            </>
        )
    }

    const onChange = (formData) => {
        if (formData.attachedFiles)
            setAttachedFilesFormData(formData.attachedFiles)

        console.log(attachedFilesFormData, formData)
        if (attachedFilesFormData && Object.keys(attachedFilesFormData).length > 0 && attachedFilesFormData.activeFiles.length > 0 && formData.attachedFiles.activeFiles.length === 0) {

            setFormData({
                ...formData,
                attachedFiles: attachedFilesFormData
            })
        } else {
            setFormData (formData);
        }

        setInteraction (true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({...notification, open: false});
        });
    };

    const onCloseDialog = () => {
        setInteraction (false);
        setFormData ((formData) => {
            let newFormData = {
                ...formData,
                companyCode: undefined,
                description: undefined,
                cost: undefined,
                mainCustomer: undefined,
                attachedFiles:[],
            }
            return newFormData;
        });
        
        if ( onClose ) {
            onClose ();
        }
    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.exceptionOut" ) }
                </DialogTitle>
            </DialogHeaderWithClose>
        <Divider />
        <DialogContent style={{padding: "0px", height: "600px"}}>
            <ReflexContainer orientation="vertical">
                <ReflexElement>
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement data-cy="left-grid-master">
                            <StockItem
                                setSelectStockItem={setSelectStockItem}
                            />
                        </ReflexElement>
                        <ReflexSplitter style={{ height: layoutConf.reflex.splitterWidth }}/>
                        <ReflexElement data-cy="left-grid-detail">
                            {
                                (selectStockItem)
                                ?
                                <StockItemLotNo
                                    selectStockItem={selectStockItem}
                                    setSelectLotNo={setSelectLotNo}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }}/>
                <ReflexElement flex={0.35}>
                    <ReflexContainer orientation="horizontal" >
                        <ReflexElement data-cy="right-form-detail">
                            <div style={{ padding: "16px" }}>
                                <AutoForm
                                    id="ExceptionInDialog"
                                    schema={modifySchema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChange}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>
        </DialogContent>
        
        <Divider />
        <DialogActionsOkCancel
            firstActionComponent={checkKeepOpen}
            labels={[ t("common.out")]}
            buttonDisabled={[!interaction, false]}
            onOk={() => onOk(modifySchema, formData)}
            onCancel={onCancel}
        />
        </Dialog>
    );
}

export default ExceptionOutDialog;

