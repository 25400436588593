import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function PopProductShipLotCreateDialogMst(props) {
    const { shipmentSelect, setDialogMstSelect } = props;
    const [, setApi] = useState();
    const [grid, setGrid] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setDialogMstSelect(grid.selectedItems[0]);
                    } else {
                        setDialogMstSelect([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setDialogMstSelect(grid.selectedItems[0]);
                    } else {
                        setDialogMstSelect([]);
                    };
                });
            }
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const onModifyViewUrl = (url) => {
        return url + '/' + shipmentSelect.materialCode + '/' + shipmentSelect.materialCodeVer;
    }

    return (
        <>
            <BasicCrudScreenType
                id="PopProductShipLotMst"
                table="PopVwProductShipLotMst"
                view="PopVwProductShipLotMst"
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                headerTitle={t("term.item")}
                onModifyViewUrl={(url) => onModifyViewUrl(url)}
                embededInOtherScreenType
                noCreate
                noEdit
                noDelete
                noExcel
            />
        </>
    );
}

export default PopProductShipLotCreateDialogMst;
