import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useRecoilState } from "recoil";
import layoutConf from "../../config/layoutConf";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import notificationState from "../../states/notificationState";
import { CellType } from "@grapecity/wijmo.grid";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import MomSysLogShip from "./MomSysLogShip";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import SaveToExcelButton from "../input/SaveToExcelButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import AccessControl from "../functional/AccessControl";
import { debounce } from "lodash";
import defineConf from "../../config/defineConf";
import MomSysShipOfOrderBuyerButton from "../input/MomSysShipOfOrderBuyerButton";
import MomSysShipDetailOfOrderBuyer from "./MomSysShipDetailOfOrderBuyer";
import ShipStateChangeButton from "../input/ShipStateChangeButton";

function MomSysShipOfOrderBuyer(props) {
    const id = "MomSysShip";
    const table = "MomSysShip";
    const view = "MomSysVwShipOfOrderBuyer";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);


    const [momSysShipSchema, setMomSysShipSchema] = useState();
    const [momSysShipGrid, setMomSysShipGrid] = useState();
    const [momSysShipApi, setMomSysShipApi] = useState();
    const [momSysShipData, setMomSysShipData] = useState();
    const [momSysShipDataIsEmpty, setMomSysShipDataIsEmpty] = useState();
    const [momSysShipSelect, setMomSysShipSelect] = useState({});

    const [disableUpdate, setDisableUpdate] = useState(true);
    const [disableDelete, setDisableDelete] = useState(true);

    const [shipState, setShipState] = useState();

    const [momSysLogShipApi, setMomSysLogShipApi] = useState();
    const [isLogData, setIsLogData] = useState(false);

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        // console.log(momSysShipSelect)
    }, [momSysShipSelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            for (let column of schema.columns) {
                switch (column.name) {
                    case "shipDate":
                        column.hideInForm = true;
                        break;
                    case "shipmentNo":
                        column.hideInForm = true;
                        break;
                    case "shipmentOrderDate":
                        column.hideInForm = true;
                        break;
                    case "shipDueDate":
                        column.hideInForm = true;
                        break;
                    case "attachedDoc":
                        column.hideInForm = false;
                        column.formItemType = "file";
                        column.formGroup = 2;
                        column.width = "100%"
                        break;
                    default:
                        break;
                }            
            }
            
            if (mounted) {
                setMomSysShipSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);


    useEffect(() => {
        (async () => {
            if (momSysShipGrid) {
                momSysShipGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysShipSelect({ ...grid.selectedItems[0], insertDelivery: true });
                            setShipState(grid.selectedItems[0]?.shipState);
                            const isHipped = parseInt(grid.selectedItems[0]?.shipState) !== defineConf.shipState.NOT_SHIP;
                            setDisableUpdate(isHipped);
                            setDisableDelete(isHipped);
                        } else {
                            setMomSysShipSelect([]);
                            setShipState([]);
                        }
                    }, 10)
                );

                momSysShipGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setMomSysShipSelect({ ...grid.selectedItems[0], insertDelivery: true });
                            setShipState(grid.selectedItems[0]?.shipState);
                            const isHipped = parseInt(grid.selectedItems[0]?.shipState) !== defineConf.shipState.NOT_SHIP;
                            setDisableUpdate(isHipped);
                            setDisableDelete(isHipped);
                        } else {
                            setMomSysShipSelect([]);
                            setShipState([]);
                        }
                    }, 10)
                );
            }
        })();
    }, [momSysShipGrid]);

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (momSysShipData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (momSysShipData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {

        if (momSysShipApi) {
            momSysShipApi.refresh();
        }

        notifySuccess(msg);
    };

    const onFailure = (msg) => {
        notifyFailure(msg.response ? msg.response.data : msg);
    };

    const doHandleBackendErrorMsg = (reason) => {
        const response = reason.response;

        return ` ${response.data} `;
    };

    const onUpdateMaster = () => {
        if (momSysShipGrid) {
            (async () => {
                const shipMst = await getSpecific("MomSysVwShip", momSysShipGrid.selectedItems[0]?.shipId);

                momSysShipGrid.selectedItems[0].shipState = shipMst.shipState;
                momSysShipGrid.refresh(false);

                setShipState(shipMst.shipState);

                const isHipped = parseInt(shipMst.shipState) !== defineConf.shipState.NOT_SHIP;
                setDisableUpdate(isHipped);
                setDisableDelete(isHipped);

            })();
        }

        if (momSysLogShipApi) {
            momSysLogShipApi.refresh();
        }
    };

    // const modifyUrlCallbackUpdate = () => {

    //     return `/api/MomSysVwShipOfOrderBuyer`
    // }

    const modifyUrlCallbackDelete = () => {

        return `/api/MomSysVwShipOfOrderBuyer`
    }

    const modifySchemaCallback = (schema, data) => {

        for (let column of schema.columns) {
            if (column.name === 'shipmentOrderDate') {
                column.hideInForm = true
            }
        }
        // schema.columns.push({
        //     name: "shipmentNo",
        //     default: null,
        //     defaultValue: "ship",
        //     hideInForm: true,
        //     nullable: "NO",
        //     readonly: true,
        //     required: true,
        //     type: "varchar(255)",
        // })

    }

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        table={table}
                        view={view}
                        noCreate
                        noDelete
                        noEdit
                        noExcel
                        headerTitle={t("term.ship")}
                        onLoadData={(data) => {
                            setMomSysShipData(data);
                            setMomSysShipDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setMomSysShipGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setMomSysShipApi(api);
                        }}
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>

                                <MomSysShipOfOrderBuyerButton
                                    id={id}
                                    schema={momSysShipSchema}
                                    refresh={momSysShipApi}
                                    customizeErrorMsgCallback={doHandleBackendErrorMsg}
                                    onSuccess={onSuccess}
                                />

                                <AccessControl accessTarget={momSysShipSchema && momSysShipSchema.name} accessMethod={momSysShipSchema && (momSysShipSchema.updateWithPatch ? "PATCH" : "PUT")}>
                                    <UpdateRecordButton
                                        id={id}
                                        schema={momSysShipSchema}
                                        grid={momSysShipGrid}
                                        refresh={momSysShipApi}
                                        disabled={disableUpdate}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        // modifyUrlCallback={modifyUrlCallbackUpdate}
                                        modifySchemaCallback={modifySchemaCallback}
                                    />
                                </AccessControl>

                                <AccessControl accessTarget={momSysShipSchema && momSysShipSchema.name} accessMethod="DELETE">
                                    <DeleteRecordButton
                                        id={id}
                                        schema={momSysShipSchema}
                                        grid={momSysShipGrid}
                                        refresh={momSysShipApi}
                                        color="secondary"
                                        disabled={disableDelete || isLogData}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        modifyUrlCallback={modifyUrlCallbackDelete}
                                    />
                                </AccessControl>

                                <AccessControl accessTarget={momSysShipSchema && momSysShipSchema.name} accessMethod={momSysShipSchema && (momSysShipSchema.updateWithPatch ? "PATCH" : "PUT")}>
                                    <ShipStateChangeButton
                                        id={id}
                                        schema={momSysShipSchema}
                                        grid={momSysShipGrid}
                                        refresh={momSysShipApi}
                                        onFailure={onFailure}
                                        onSuccess={onSuccess}

                                    />
                                </AccessControl>

                                <SaveToExcelButton
                                    id="EguProductShip"
                                    view={view}
                                    grid={momSysShipGrid}
                                    color="primary"
                                    exportFormatItem={exportFormatItem}
                                    disabled={momSysShipDataIsEmpty}
                                />
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="Detail">
                    {momSysShipSelect ? (
                        <ReflexContainer orientation="vertical">
                            <ReflexElement flex={0.5}>
                                <MomSysShipDetailOfOrderBuyer
                                    onSelectMst={momSysShipSelect}
                                    onUpdateMaster={onUpdateMaster}
                                    shipState={shipState}
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                />
                            </ReflexElement>

                            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                            <ReflexElement flex={0.5} data-cy="DetailLog">
                                <MomSysLogShip
                                    onSelectMst={momSysShipSelect}
                                    onLogShipApiInitialized={(api) => {
                                        setMomSysLogShipApi(api);
                                    }}
                                    onUpdateMaster={onUpdateMaster}
                                    onSuccess={onSuccess}
                                    setIsLogData={setIsLogData}
                                />
                            </ReflexElement>
                        </ReflexContainer>
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysShipOfOrderBuyer;
