import { useState } from "react";
import MomSysShipActionButtonLayout from "../layout/MomSysShipActionButtonLayout";
import MomSysShipActionDialog from "../dialogTypes/MomSysShipActionDialog";

function MomSysShipActionButton(props) {
    const { id, schema, refresh, onUpdateMaster, onShipSelectMst, onSuccess, customizeErrorMsgCallback, notCheckedShipQty, ...others } =
        props;
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onShipSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (onUpdateMaster) {
            onUpdateMaster();
        }

        if (refresh) {
            refresh.refresh();
        }
    };

    return (
        <>
            <MomSysShipActionButtonLayout id={id + "-ship-action-button"} onClick={onOpen} {...others} />
            <MomSysShipActionDialog
                id={id + "-shipAction-dialog"}
                schema={schema}
                onShipSelectMst={onShipSelectMst}
                open={open}
                onClose={onClose}
                onSuccess={onShipSuccess}
                isCheckedShipQty={notCheckedShipQty}
            />
        </>
    );
}

export default MomSysShipActionButton;
