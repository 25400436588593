import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useState, useEffect, useRef } from "react";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import AutoForm from "../form/functional/AutoForm";
import ReportViewer from "../input/ReportViewer";
import apiClient from "../../lib/common/apiClient";
import Iframe from 'react-iframe'
import { debounce } from "lodash";
import getSpecific from "../../lib/api/getSpecific";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogTitle from "../dialog/functional/DialogTitle";
import ResizeObserver from 'resize-observer-polyfill';

function GeneralReport ( props ) {
    const { t } = useTranslation ();
    // const [grid, setGrid] = useState();
    const [height, setHeight] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null); // 선택된 항목을 저장할 상태 변수
    const [reportUrl, setReportUrl] = useState("")
    const [generalReportTargetGrid, setGeneralReportTargetGrid] = useState();
    const [generalReportTargetSelect, setGeneralReportTargetSelect] = useState();
    const [reportInputSchema, setReportInputSchema] = useState()
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const [formData, setFormData] = useState({});
    const [firstPanelHeight, setFirstPanelHeight] = useState('');
    
    const firstPanelRef = useRef(null);
    

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    const updateHeight = () => {
        if (firstPanelRef.current) {
          const contentHeight = firstPanelRef.current.scrollHeight;
          
          if (contentHeight !== firstPanelHeight) {
            setFirstPanelHeight(`${contentHeight}`);
          }
        }
    };

    


    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();
        updateHeight();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {

        
        updateHeight();


        const resizeObserver = new ResizeObserver(updateHeight);

        if (firstPanelRef.current) {
            resizeObserver.observe(firstPanelRef.current);
        }

        return () => {
        if (firstPanelRef.current) {
            resizeObserver.unobserve(firstPanelRef.current);
        }
        resizeObserver.disconnect();
    };

    }, [firstPanelHeight]);
    
    useEffect(() => {
        (async () => {
            if (generalReportTargetGrid) {
                generalReportTargetGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (generalReportTargetGrid.selectedItems && generalReportTargetGrid.selectedItems[0]) {
                            
                            setGeneralReportTargetSelect(generalReportTargetGrid.selectedItems[0]);
                            
                            updateHeight()
                        } else {
                            
                            setGeneralReportTargetSelect();
                            updateHeight()
                        }
                    }, 0)
                );

                generalReportTargetGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setGeneralReportTargetSelect(grid.selectedItems[0])
                        updateHeight()

                    } else {

                        setGeneralReportTargetSelect();
                        updateHeight()

                    };

                }, 0));

                
            }
        })();
    }, [generalReportTargetGrid]);

    useEffect(() => {
        if (generalReportTargetGrid && generalReportTargetSelect) {
            // get schema api
            // api
            (async () => {
                setReportInputSchema()
                setReportUrl("")
                
                let reportInputSchema = await getSpecific("GeneralReport/reportschema/"+generalReportTargetSelect.filename);

                if (reportInputSchema.reportschema) {
                    setReportInputSchema(reportInputSchema.reportschema);
                }
            })();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generalReportTargetSelect]);

    
    useEffect(() => {
        if (generalReportTargetGrid && generalReportTargetSelect) {
            (async () => {
                setReportInputSchema()
                let reportInputSchema = await getSpecific("GeneralReport/reportschema/"+generalReportTargetSelect.filename);

                if (reportInputSchema.reportschema) {
                    setReportInputSchema(reportInputSchema.reportschema);
                }
            })();
        }
        
    }, []);


    const onInitialized = grid => {
        
        grid.selectionMode = "Row";
        setGeneralReportTargetGrid(grid);

        
        // if (grid.selectedItems && grid.selectedItems[0]) {

        //     setGeneralReportTargetSelect(grid.selectedItems[0]);
        // } else {
        //     setGeneralReportTargetSelect();
        // }

    };
    
    const onModifyViewSchema = ( schema ) => {

        for ( const column of schema.columns ) {

        
            switch ( column.name ) {

                case "reportName":

                    column.grid = {...column.grid, width: 300 };

                    break;

                case "filename":

                    column.grid = {...column.grid, hide: true };

                    break;
                                

                default:

                    column.grid = {...column.grid, hide: true };

                    break;

            
            }

        }

        

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.notFound", 
            { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }


        // report api
        const path = generalReportTargetSelect.filename 
        let url = `/report/GeneralReportApis/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }
        
        apiClient
            .post ( url, formData )
            .then ( ( response ) => {
                
                let blob = new Blob ( [ response.data ], {
                    type: "text/html;charset=utf-8;"
                } );

                let url = window.URL.createObjectURL ( blob );
                setReportUrl(url)
                

            } )
            .catch((reason) => onApiFailure(reason));
            
    };

    

    const onChange = ( formData ) => {

        setFormData(formData)
    }

    return (
        <div style={{ height: height}}>

            <ReflexContainer  orientation="vertical">

                <ReflexElement  flex={0.2} >
                
                    <BasicCrudScreenType
                        id="GeneralReport"
                        embededInOtherScreenType
                        headerTitle={t("term.generalreportlist")}
                        table='GeneralReportTenant'
                        view="VwGeneralReportTenant"
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        noFilter
                        onInitialized={onInitialized}
                        onModifyViewUrl={(url) => `${url}/reportname`}
                        onModifyViewSchema={onModifyViewSchema}
                    />
                </ReflexElement>

                <ReflexSplitter
                    style={{ width: layoutConf.reflex.splitterHeight }}
                />

                <ReflexElement>

                    <ReflexContainer orientation="horizontal">

                        <ReflexElement 
                            // minSize={'200'} 
                            // size={'200'} 
                            size={firstPanelHeight}
                            propagateDimensionsRate={200}
                            >
                            <div ref={firstPanelRef}> 
                            
                                <DialogHeader>
                                    <DialogTitle>
                                        { t ( "term.enterreportconditions" ) }
                                    </DialogTitle>
                                </DialogHeader>

                                <Divider />

                                {reportInputSchema && (
                                    
                                    <AutoForm
                                        style={{marginTop:'0px', marginLeft:'2%', marginRight:'2%'}}
                                        id="generalreport-input-form"
                                        schema={reportInputSchema}
                                        mode="create"
                                        // initialData={formData}
                                        onChange={onChange}
                                    />
                                )}
                                
                                <Divider />
                                
                                <DialogActionsOkCancel    
                                    labels={[t("common.ok")]}
                                    buttonDisabled={[false, true]} /* [okButtonDisabled, cancelButtonDisabled] */
                                    onOk={() => onOk(reportInputSchema, formData)}
                                    // onCancel={onCancel}
                                />
                            </div>

                        </ReflexElement>
                                
                        <ReflexSplitter
                            style={{ height: layoutConf.reflex.splitterWidth }}
                        />

                        <ReflexElement minSize={300}>
                            <div style={{height:'100%'}}>
                            
                                {reportUrl && (
                                    <Iframe url= {reportUrl}
                                    width="100%"
                                    height="100%"
                                    id=""
                                    className=""
                                    display="block"
                                    position="relative"/>
                                    
                                )}

                                <Snackbar
                                    open={notification.open && notification.severity !== "success"}
                                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                                    onClose={onCloseNotification}
                                >
                                    <Alert
                                        onClose={onCloseNotification}
                                        variant="filled"
                                        severity={notification.severity}
                                    >

                                        {notification.msg}

                                    </Alert>

                                </Snackbar>
                            </div>
                            
                        </ReflexElement>

                    </ReflexContainer>

                </ReflexElement>

            </ReflexContainer>

            
        </div>
        
        
    );

}

export default GeneralReport;
