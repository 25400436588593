/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authProfileState from "../../states/authProfileState";
import { useRecoilValue } from "recoil";
import getSchema from "../../lib/api/getSchema";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { CellType } from "@grapecity/wijmo.grid";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import SaveToExcelButton from "../input/SaveToExcelButton";
import EguOutSourcingPurchaseDetail from "./EguOutSourcingPurchaseDetail.js";
import EguOutSourcingPurchaseCreateButton from "../input/EguOutSourcingPurchaseCreateButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import ApprovalButton from "../input/ApprovalButton";
import AccessControl from "../functional/AccessControl";
import { debounce } from "lodash";
import defineConf from "../../config/defineConf";

function EguOutSourcingPurchase(props) {
    const id = "EguOutSourcingPurchase";
    const table = "EguOutSourcingPurchase";
    const view = "EguVwOutSourcingPurchase";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [eguOutSourcingPurchaseSchema, setEguOutSourcingPurchaseSchema] = useState();
    const [eguOutSourcingPurchaseGrid, setEguOutSourcingPurchaseGrid] = useState();
    const [eguOutSourcingPurchaseApi, setEguOutSourcingPurchaseApi] = useState();
    const [eguOutSourcingPurchaseData, setEguOutSourcingPurchaseData] = useState();
    const [eguOutSourcingPurchaseDataIsEmpty, setEguOutSourcingPurchaseDataIsEmpty] = useState();
    const [eguOutSourcingPurchaseDetailDataIsEmpty, setEguOutSourcingPurchaseDetailDataIsEmpty] = useState();
    const [eguOutSourcingPurchaseSelect, setEguOutSourcingPurchaseSelect] = useState({});

    const authProfile = useRecoilValue(authProfileState);

    const [approvalCtx, setApprovalCtx] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                setEguOutSourcingPurchaseSchema(schema);
            }
        })();

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (eguOutSourcingPurchaseGrid) {
                eguOutSourcingPurchaseGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingPurchaseSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingPurchaseSelect([]);
                        }
                    },10)
                );

                eguOutSourcingPurchaseGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguOutSourcingPurchaseSelect(grid.selectedItems[0]);
                        } else {
                            setEguOutSourcingPurchaseSelect([]);
                        }
                    },10)
                );
            }
        })();
    }, [eguOutSourcingPurchaseGrid]);

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 19,
            tableName: table,
            statusColumn: "approvalStateId",
            schema: eguOutSourcingPurchaseSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.eguOutSourcingPurchase", {
                    outSourcingId: item.outSourcingId,
                    outSourcingName: item.outSourcingName,
                })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);
    }, [eguOutSourcingPurchaseSchema]);

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (eguOutSourcingPurchaseData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (eguOutSourcingPurchaseData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.65} data-cy="Master">
                    <BasicCrudScreenType
                        id={id}
                        headerTitle={t("term.eguOutSourcingPurchase")}
                        table={table}
                        view={view}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setEguOutSourcingPurchaseGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguOutSourcingPurchaseApi(api);
                        }}
                        onLoadData={(data) => {
                            setEguOutSourcingPurchaseData(data);
                            setEguOutSourcingPurchaseDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <AccessControl
                                    accessTarget={eguOutSourcingPurchaseSchema && eguOutSourcingPurchaseSchema.name}
                                    accessMethod="POST"
                                >
                                    <EguOutSourcingPurchaseCreateButton
                                        id={id}
                                        schema={eguOutSourcingPurchaseSchema}
                                        refresh={eguOutSourcingPurchaseApi}
                                        onSuccess={onSuccess}
                                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguOutSourcingPurchaseSchema && eguOutSourcingPurchaseSchema.name}
                                    accessMethod="PUT"
                                >
                                    <UpdateRecordButton
                                        id={id}
                                        schema={eguOutSourcingPurchaseSchema}
                                        grid={eguOutSourcingPurchaseGrid}
                                        refresh={eguOutSourcingPurchaseApi}
                                        disabled={
                                            eguOutSourcingPurchaseSelect?.approvalState ||
                                            eguOutSourcingPurchaseSelect?.outSourcingShipId ||
                                            eguOutSourcingPurchaseDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguOutSourcingPurchaseSchema && eguOutSourcingPurchaseSchema.name}
                                    accessMethod="DELETE"
                                >
                                    <DeleteSingleRecordButton
                                        id={id}
                                        schema={eguOutSourcingPurchaseSchema}
                                        grid={eguOutSourcingPurchaseGrid}
                                        refresh={eguOutSourcingPurchaseApi}
                                        color="secondary"
                                        makeItemName={(row) => {
                                            return `${t("columnName.outSourcingId")}: ${row.outSourcingId}`;
                                        }}
                                        disabled={
                                            eguOutSourcingPurchaseSelect?.approvalState ||
                                            eguOutSourcingPurchaseSelect?.outSourcingShipId ||
                                            eguOutSourcingPurchaseDataIsEmpty ||
                                            !eguOutSourcingPurchaseDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguOutSourcingPurchaseSchema && eguOutSourcingPurchaseSchema.name}
                                    accessMethod="APPROVAL"
                                >
                                    <ApprovalButton
                                        id={id}
                                        grid={eguOutSourcingPurchaseGrid}
                                        refresh={eguOutSourcingPurchaseApi}
                                        approvalCtx={approvalCtx}
                                        disabled={
                                            eguOutSourcingPurchaseSelect?.outSourcingShipId ||
                                            eguOutSourcingPurchaseDataIsEmpty ||
                                            eguOutSourcingPurchaseDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguOutSourcingPurchaseSchema && eguOutSourcingPurchaseSchema.name}
                                    accessMethod="SAVETOEXCEL"
                                >
                                    <SaveToExcelButton
                                        id={id}
                                        grid={eguOutSourcingPurchaseGrid}
                                        view={view}
                                        color="primary"
                                        exportFormatItem={exportFormatItem}
                                        disabled={eguOutSourcingPurchaseDataIsEmpty}
                                    />
                                </AccessControl>
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.35} data-cy="Detail">                     
                        <EguOutSourcingPurchaseDetail
                            onSelectMst={eguOutSourcingPurchaseSelect}
                            onDetailEmpty={(isEmpty) => {
                                setEguOutSourcingPurchaseDetailDataIsEmpty(isEmpty);
                            }}
                        />                    
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguOutSourcingPurchase;
