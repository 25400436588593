/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";

import ActionButtonLayout from "../layout/ActionButtonLayout";

function MenuMigrationCreateQueryButton(props) {
    const { t } = useTranslation();

    const { id, selectedItem, title, refresh, onSuccess, onFailure, onDeleteQuerys, ...others } = props;

    return (
        <>
            <ActionButtonLayout id={id + "-button"} onClick={onDeleteQuerys} {...others}>
                {t("buttonName.menuDeleteQuery")}
            </ActionButtonLayout>
        </>
    );
}

export default MenuMigrationCreateQueryButton;
