/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import { debounce } from "lodash";
import OrderPurchaseInputButton from "../input/OrderPurchaseInputButton";
import OrderPurchaseInputDetail from "./OrderPurchaseInputDetail";
import UpdateRecordButton from "../input/UpdateRecordButton";
import getSchema from "../../lib/api/getSchema";

function OrderPurchaseInput(props) {
  const [grid, setGrid] = useState();

  const [onSelectMst, setOnSelectMst] = useState();

  const [height, setHeight] = useState(0);

  const { t } = useTranslation();

  const [mstApi, setMstApi] = useState();

  const [schema, setSchema] = useState();

  const setNotification = useSetRecoilState(notificationState);

  const [isEmpty, setIsEmpty] = useState();

  const handleResize = () => {
    setHeight(document.documentElement.offsetHeight - 96);
  };

  useEffect(() => {
    let mounted = true;

    window.addEventListener("resize", handleResize);
    handleResize();

    (async () => {
      let schema = await getSchema("OrderPurchaseInputMst");

      if (mounted) {
        setSchema(schema);
      }
    })();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (grid) {
        grid.selectionChanged.addHandler(
          debounce((grid, event) => {
            if (grid.selectedItems && grid.selectedItems[0]) {
              setOnSelectMst(grid.selectedItems[0]);
            } else {
              setOnSelectMst([]);
            }
          }, 10)
        );

        grid.itemsSourceChanged.addHandler(
          debounce((grid, event) => {
            if (grid.selectedItems && grid.selectedItems[0]) {
              setOnSelectMst(grid.selectedItems[0]);
            } else {
              setOnSelectMst([]);
            }
          }, 10)
        );
      }
    })();
  }, [grid]);

  const onInitialized = (grid) => {
    grid.selectionMode = "Row";

    setGrid(grid);
  };

  const onApiInitialized = (api) => {
    setMstApi(api);
  };

  const onLoadData = (data) => {

    setIsEmpty(data.length < 1 ? true : false)

}

  const notifySuccess = (msg) => {
    setNotification({
      severity: "success",
      msg: msg,
      open: true,
    });
  };

  const onSuccess = (msg) => {
    notifySuccess(msg);
  };

  const modifySchemaCallback = (schema) => {
    const excludedColumns = [
      "qty",
      "inWareHouseCode",
      "manufacturedDate",
      "expirationDate",
      "inputDate",
      "price",
      "amount",
      "jaho",
      "ptQty",
    ];

    schema.columns = schema.columns.filter((column) => {
      if (excludedColumns.includes(column.name)) {
        return false;
      }

      column.hideInForm = column.name !== "attachedFiles";
      return true;
    });
  };

  return (
    <div style={{ height: height }}>
      <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.6}>
          <BasicCrudScreenType
            id="OrderPurchaseInputMst"
            headerTitle={t("term.orderPurchaseInputMst")}
            table="OrderPurchaseInputMst"
            view="VwOrderPurchaseInputMst"
            onInitialized={onInitialized}
            onApiInitialized={onApiInitialized}
            onLoadData = {onLoadData}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
              <>
                <BasicCrudScreenActionItemLayout>
                  <OrderPurchaseInputButton
                    id="OrderPurchaseInputMst"
                    schema={schema}
                    refresh={mstApi}
                    onSuccess={onSuccess}
                  />
                </BasicCrudScreenActionItemLayout>
                <BasicCrudScreenActionItemLayout>
                  <UpdateRecordButton
                    id={"OrderPurchaseInputMst"}
                    schema={schema}
                    grid={grid}
                    refresh={mstApi}
                    disabled={isEmpty}
                    onSuccess={onSuccess}
                    modifySchemaCallback={modifySchemaCallback}
                  />
                </BasicCrudScreenActionItemLayout>
              </>
            )}
          />
        </ReflexElement>

        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

        <ReflexElement>
          {onSelectMst ? (
            <OrderPurchaseInputDetail onSelectMst={onSelectMst} />
          ) : (
            <></>
          )}
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}

export default OrderPurchaseInput;
