import { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

function MomSysFailLotSplitButton (props) {

    const {id, refresh, onSuccess, createButtonName, customizeErrorMsgCallback, onSelect, ...others} = props;

    const { t } = useTranslation();

    const [ open, setOpen ] = useState(false);
    const [ schema, setSchema ] = useState(false);

    useEffect(() => {

        let schema = {

            name: "MomSysFailLotManagement",
            type: "BASE TABLE",
            formGroups: ["disabled", "input"],
            searchAll: false,

            columns: [
                {
                    name: "lotNo",
                    type: "varchar(50)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 10,
                    formItemType: "text",
                },
                {
                    name: "materialCode",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 20,
                    formItemType: "text",
                },
                {
                    name: "materialCodeVer",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 30,
                    formItemType: "text",
                },
                {
                    name: "materialName",
                    type: "varchar(200)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 40,
                    formItemType: "text",
                },
                {
                    name: "qtyCurrent",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    disabled: true,
                    formGroup: 0,
                    formOrder: 50,
                    formItemType: "float",
                },
                {
                    name: "qty",
                    type: "bigint(20)",
                    nullable: "NO",
                    default: null,
                    required: true,
                    formGroup: 1,
                    formOrder: 60,
                    formItemType: "float",
                    validation: {
                        msg: "error.floatRequired",
                        type: "regexp",
                        value: "^(?!0(\\.0+)?$)(\\d+(\\.\\d+)?)$",
                    }
                },
                
            ]

        };

        setSchema(schema);

    },[]);

    const onOpen = () => {

        setOpen ( true );

    };

    const onClose = () => {

        setOpen ( false );

    };

    const onCreateSuccess = ( msg ) => {

        if ( onSuccess ) {

            onSuccess ( msg );

        }

        if(refresh) {
            refresh.refresh ();
        }

    };

    const setInitialDataCallback = ( formData ) => {

        if ( !formData ) {

            formData = {};

        }

        formData.lotNo = onSelect.lotNo;
        formData.orderBuyerId = onSelect.orderBuyerId;
        formData.materialCode = onSelect.materialCode;
        formData.materialCodeVer = onSelect.materialCodeVer;
        formData.materialName = onSelect.materialName;
        formData.lineNo = onSelect.lineNo;
        formData.processCode = onSelect.processCode;
        formData.qtyCurrent = onSelect.qtyCurrent;
        formData.failureCode = onSelect.failureCode;
        formData.machineCode = onSelect.machineCode;
        formData.processId = onSelect.processId;

    }

    const modifyUrlCallback = (url) => {

        return url + "/failLotSplit"

    }

    const checkItemValidation = ( formData, column ) => {

        if(column.name === "qty") {

            if(formData["qtyCurrent"] <= formData["qty"]) {

                return [ false, t( "error.failToSplitQtyunderZero" ) ];
            }
        }

        return [ true, "" ];

    }

    return (
        <>
            <CreateRecordButtonLayout id = {id+"-failLotSplit-button"} onClick = {onOpen} createButtonName ={createButtonName} {...others}/>
            <AutoFormDialog
                id={id+"failLotSplit-dialog"}
                mode="create"
                formId={id+"failLotSplit-form"}
                title={schema ? t(`dialog.title.${schema.name}.${'failLotSplit'}`) : ""}
                schema={schema}
                initialData={undefined}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                modifyUrlCallback = {modifyUrlCallback}
                setInitialDataCallback ={setInitialDataCallback}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
                checkItemValidationCallback={checkItemValidation}
            />
        </>
    )

}

export default MomSysFailLotSplitButton;