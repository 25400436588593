import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import IncomingInspectionTarget from './IncomingInspectionTarget';
import InspectionGroup from './InspectionGroup';
import InspectionItem from './InspectionItem';
import InspectionResult from './InspectionResult';
import defineConf from '../../config/defineConf';

function IncomingInspection ( props ) {
    const [height, setHeight] = useState(0);
    const [selectMstGrid, setSelectMstGrid] = useState();
    const [mstApi, setMstApi] = useState();
    const [selectGroupByMaterialGrid, setSelectGroupByMaterialGrid] = useState();
    const [selectGroupItemGrid, setSelectGroupItemGrid] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const masterGridSetting = (data) => {
        setSelectMstGrid(data)
    } 

    const masterApiSetting = (api) => {
        setMstApi(api);
    }

    const groupByMaterialGridSetting = (data) => {
        setSelectGroupByMaterialGrid(data);
    }

    const groupItemGridSetting = (data) => {
        setSelectGroupItemGrid(data);
    }

    return (
        <div style={{height: height}}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement>
                    <IncomingInspectionTarget 
                        id="IncomingInspectionTarget"
                        masterGridSetting={masterGridSetting}
                        masterApiSetting={masterApiSetting}
                    />
                </ReflexElement>

                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />

                <ReflexElement flex={0.5}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.3}>
                            {
                                (selectMstGrid)
                                ?
                                <InspectionGroup 
                                    id="InspectionGroupByMaterial" 
                                    selectMstGrid={selectMstGrid}
                                    groupByMaterialGridSetting={groupByMaterialGridSetting}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>

                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />

                        <ReflexElement flex={0.5}>
                            {   
                                (selectGroupByMaterialGrid)
                                ?
                                <InspectionItem
                                    id="InspectionGroupItem" 
                                    selectMstGrid={selectMstGrid}
                                    selectGroupByMaterialGrid={selectGroupByMaterialGrid}
                                    groupItemGridSetting={groupItemGridSetting}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>

                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />

                        <ReflexElement flex={0.5}>
                            {
                                (selectGroupItemGrid)
                                ?
                                <InspectionResult 
                                    id="inspectionResult"
                                    selectMstGrid={selectMstGrid}
                                    selectGroupItemGrid={selectGroupItemGrid}
                                    mstApi={mstApi}
                                    inspectionType={defineConf.inspectionType.INPUT_INSPECTION}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default IncomingInspection;
