/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import checkValidation from "../../lib/utils/checkValidation";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import apiClient from "../../lib/common/apiClient";
import FormItemSelect from "../form/input/FormItemSelect";
import AutoFilterFormDateRangeWithButtons from "../filter/functional/AutoFilterFormDateRangeWithButtons";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function MigrationInsertTenantCreateDialog (props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, ...others
    } = props;

    const  classes = useStyles ();
    const {t} = useTranslation ();

    const [instance, setInstance] = useState ();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    
    
    const onApiFailure = (reason) => {
        notifyFailure (t(reason.response.data));
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning (msg);
                return false;
            }
        }
        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };


    const onOk = () => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (!keepOpen) instance.hide();

        if (onSuccess) {

            setTimeout ( onSuccess(formData), 0 );

        }
    }

    
    const onCancel = () => {
        setFormData ((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>
            </>
        )
    }

    
    const onChange = (formData) => {
        
        setFormData (formData);
        setInteraction (true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({...notification, open: false});
        });
    };

    const onCloseDialog = () => {
        setInteraction (false);
        setFormData ((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });
        
        if ( onClose ) {
            onClose ();
        }
    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="md"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.MigrationInsertTenantCreate" ) }
                </DialogTitle>
            </DialogHeaderWithClose>
        <Divider />

        <DialogContent style={{padding: "0px"}}>
            <ReflexContainer orientation="vertical">
                
                <ReflexElement>
                    <div
                        style={{
                            padding: "16px"
                        }}                    
                    >
                        <div>
                            <AutoForm
                                id="migration-insert-tenant-create-form"
                                schema={schema}
                                mode="edit"
                                initialData={initialData}
                                onChange={onChange}
                            />
                        </div>
                        <hr />
                    </div>
                </ReflexElement>
            </ReflexContainer>

            <Snackbar
                open={notification.open && notification.severity !== "success"}
                autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>
        </DialogContent>
        <Divider />
        <DialogActionsOkCancel
            firstActionComponent={checkKeepOpen}
            labels={[ t("common.add")]}
            buttonDisabled={[!interaction, false]}
            onOk={() => onOk(schema, formData)}
            onCancel={onCancel}
        />
        </Dialog>
    );
}

export default MigrationInsertTenantCreateDialog;
