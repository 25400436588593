import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import getList from "../../lib/api/getList";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

async function getDisabledList(eguOutSourcingPurchaseSchema, onDialogSelect) {
    const apiResult = await getList("EguVwOutSourcingPurchaseNotToBeSelectedProcess/" + onDialogSelect.taskId);

    const processArr = [];
    apiResult.data.map((row) => processArr.push(row.processCode));

    const modifiedSchema = cloneDeep(eguOutSourcingPurchaseSchema);

    for (const column of modifiedSchema.columns) {
        switch (column.name) {
            case "startProcessCode":
                column.codeInfo = {
                    tableName: `MomSysVwOrderedBillOfProcess/${onDialogSelect.materialCode}/${onDialogSelect.materialCodeVer}`,
                    nameColumn: ["processOrder", "processCode", "processName"],
                    valueColumn: "processCode",
                    seperator: ": ",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                };
                column.getOptionDisabled = (option) => {
                    return processArr.includes(option.value);
                };

                break;

            case "endProcessCode":
                column.codeInfo = {
                    tableName: `MomSysVwOrderedBillOfProcess/${onDialogSelect.materialCode}/${onDialogSelect.materialCodeVer}`,
                    nameColumn: ["processOrder", "processCode", "processName"],
                    valueColumn: "processCode",
                    seperator: ": ",
                    useEmptyValue: true,
                    emptyName: "term.none",
                    sortByName: true,
                };
                column.getOptionDisabled = (option) => {
                    return processArr.includes(option.value);
                };

                break;

            default:
                break;
        }
    }

    return modifiedSchema;
}

function EguOutSourcingPurchaseDialog(props) {
    const {
        id,
        schema, // EguOutSourcingPurchase Schema

        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        title,
        formId,

        onDialogUpdateDetail,
        orderBuyerId,
        modifyUrlCallback,

        customizeErrorMsgCallback,

        ...others
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState();
    const [keepOpen, setKeepOpen] = useState(false);

    const [tableSchema, setTableSchema] = useState();

    const [eguVwOutSourcingPurchaseTargetGrid, setEguVwOutSourcingTargetGrid] = useState();
    const [eguVwOutSourcingPurchaseTargetApi, seteEuVwOutSourcingPurchaseOutSourcedApiApi] = useState();
    const [eguVwOutSourcingPurchaseTargetSelect, setEguVwOutSourcingTargetSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifySuccess = (msg) => {
        notifySuccess(msg);

        if (onDialogUpdateDetail) {
            onDialogUpdateDetail();
        }
    };

    useEffect(() => {
        (async () => {
            if (eguVwOutSourcingPurchaseTargetGrid) {
                eguVwOutSourcingPurchaseTargetGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguVwOutSourcingTargetSelect(grid.selectedItems[0]);
                        } else {
                            setEguVwOutSourcingTargetSelect();
                        }
                    }, 10)
                );

                eguVwOutSourcingPurchaseTargetGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguVwOutSourcingTargetSelect(grid.selectedItems[0]);
                        } else {
                            setEguVwOutSourcingTargetSelect();
                        }
                    }, 10)
                );
            }
        })();
    }, [eguVwOutSourcingPurchaseTargetGrid]);

    useEffect(() => {
        if (eguVwOutSourcingPurchaseTargetSelect) {
            (async () => {
                setTableSchema(await getDisabledList(schema, eguVwOutSourcingPurchaseTargetSelect));

                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        planId: eguVwOutSourcingPurchaseTargetSelect.taskId,
                        taskName: eguVwOutSourcingPurchaseTargetSelect.taskName,
                        orderBuyerId: eguVwOutSourcingPurchaseTargetSelect.orderBuyerId,
                        orderBuyerName: eguVwOutSourcingPurchaseTargetSelect.orderBuyerName,

                        outSourcingQty: eguVwOutSourcingPurchaseTargetSelect.plannedQty,
                        materialCode: eguVwOutSourcingPurchaseTargetSelect.materialCode,
                        materialCodeVer: eguVwOutSourcingPurchaseTargetSelect.materialCodeVer,
                        materialName: eguVwOutSourcingPurchaseTargetSelect.materialName,
                        startProcessCode: null,
                        endProcessCode: null,
                        outSourcingDate: new Date(),
                    };

                    for (let column of schema.columns) {
                        if (column.defaultValue !== undefined) {
                            newFormData[column.name] = column.defaultValue;
                        }
                    }

                    return newFormData;
                });
            })();
        }
    }, [eguVwOutSourcingPurchaseTargetSelect, schema]);

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                dialogInstance.hide();
            } else {
                (async () => {
                    setTableSchema(await getDisabledList(schema, eguVwOutSourcingPurchaseTargetSelect));
                })();

                if (eguVwOutSourcingPurchaseTargetApi) {
                    eguVwOutSourcingPurchaseTargetApi.refresh();
                }
            }

            if (onSuccess) {
                setTimeout(() => onSuccess(result.data), 0);
            } else {
                onNotifySuccess(result.data);
            }

            return keepOpen;
        });
    };

    const onApiFailure = (reason) => {
        if (customizeErrorMsgCallback) {
            notifyFailure(customizeErrorMsgCallback(reason));
        } else {
            notifyFailure(
                t("error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify(reason) })
            );
        }
    };

    const onOk = (schema, formData) => {
        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        let url = `/api/${schema.name}`;

        if (modifyUrlCallback) {
            url = modifyUrlCallback(url);
        }

        let apiData = { ...formData };

        let form = new FormData();

        let json = {};
        let deleted = {};

        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }
        
        // for (const formItem in apiData) {
        //     if (apiData[formItem] !== undefined) {
        //         json[formItem] = apiData[formItem];
        //     }
        // }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        setEguVwOutSourcingTargetSelect();

        setOkButtonDisabled(true);

        dialogInstance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        setEguVwOutSourcingTargetSelect();

        setOkButtonDisabled(true);

        setFormData();

        if (onClose) {
            onClose();
        }
    };

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeader>
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.5}>
                        <BasicCrudScreenType
                            id="EguVwOutSourcingPurchaseMstTargetList"
                            embededInOtherScreenType
                            headerTitle={t("term.eguOutSourcingPurchaseOutSourced")}
                            view="EguVwOutSourcingPurchaseMstTargetList"
                            noCreate
                            noEdit
                            noDelete
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setEguVwOutSourcingTargetGrid(grid);
                            }}
                            onApiInitialized={(api) => {
                                seteEuVwOutSourcingPurchaseOutSourcedApiApi(api);
                            }}
                        />
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.5}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="out-sourcing-purchase-create-form"
                                schema={tableSchema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default EguOutSourcingPurchaseDialog;
