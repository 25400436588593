import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import authProfileState from "../../states/authProfileState";
import defineConf from "../../config/defineConf";

import ActionButtonLayout from "../layout/ActionButtonLayout";
import AutoFormDialog from "../dialogTypes/AutoFormDialog";

import getExpirationDate from "../../lib/utils/getExpirationDate";

function PopOutSourcingInputDoInputButton(props) {
    const { id, schema, onSelectMst, refreshFtn, onSuccess, onFailure, customizeErrorMsgCallback, modifyUrlCallback, ...others } = props;

    const { t } = useTranslation();

    const authProfile = useRecoilValue(authProfileState);

    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState();

    const onOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (onSelectMst && schema) {
            setFormData((formData) => {
                let toBeInputQty = onSelectMst.fakeInputTotalQty ? Number(onSelectMst.outSourcingQty) - Number(onSelectMst.fakeInputTotalQty) : Number(onSelectMst.outSourcingQty);

                if (toBeInputQty < 0) {
                    toBeInputQty = 0;
                }

                let newFormData = {
                    ...formData,

                    outSourcingId: onSelectMst.outSourcingId,
                    outSourcingName: onSelectMst.outSourcingName,

                    outSourcingFakeInputId: onSelectMst.outSourcingFakeInputId,

                    materialCode: onSelectMst.outSourcingMaterialCode,
                    materialCodeVer: onSelectMst.outSourcingMaterialCodeVer,
                    materialName: onSelectMst.outSourcingMaterialName,
                    startProcessName: onSelectMst.startProcessName,
                    endProcessName: onSelectMst.endProcessName,

                    qty: toBeInputQty,
                    mediumUnitQty: toBeInputQty * onSelectMst.materialMediumUnitQty,
                    smallUnitQty: toBeInputQty * onSelectMst.materialSmallUnitQty,

                    inputManager: authProfile.username,

                    lotNo: onSelectMst.lotNo,
                    extLotNo: onSelectMst.extLotNo,

                    manufacturedDate: new Date(),
                    
                    expirationDate: getExpirationDate(
                        onSelectMst.standardExpirationDate,
                        new Date()
                    ),
                };

                for (const column of schema.columns) {
                    if (column?.defaultValue !== undefined) {
                        newFormData[column.name] = column.defaultValue;
                    }
                }

                return newFormData;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectMst, schema]);

    const onClose = () => {
        setOpen(false);
    };

    const onInputSuccess = (msg) => {
        if (onSuccess) {
            onSuccess(msg);
        }

        if (refreshFtn) {
            refreshFtn();
        }
    };

    return (
        <React.Fragment>
            <ActionButtonLayout id={id + "-input-button"} onClick={onOpen} {...others}>
                {t("buttonName.input")}
            </ActionButtonLayout>
            <AutoFormDialog
                id={id + "-input-dialog"}
                mode="create" // Note!!
                formId={id + "-input-form"}
                title={schema ? t(`dialog.title.${schema.name}.input`) : ""}
                schema={schema}
                modifyUrlCallback={modifyUrlCallback ? modifyUrlCallback : (url) => `/api/OutSourcingInput/doInput`}
                initialData={formData}
                setInitialDataCallback={(newFormData) => {
                    for (const item in formData) {
                        newFormData[item] = formData[item];
                    }

                    newFormData.mediumUnitQty = formData.qty * onSelectMst.materialMediumUnitQty;
                    newFormData.smallUnitQty = formData.qty * onSelectMst.materialSmallUnitQty;
                }}
                modifyFormDataCallback={(formData, name) => {
                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                        formData.defectiveCode = "INS000";
                    }

                    formData.mediumUnitQty = formData.qty * onSelectMst.materialMediumUnitQty;
                    formData.smallUnitQty = formData.qty * onSelectMst.materialSmallUnitQty;
                }}
                open={open}
                onClose={onClose}
                onSuccess={onInputSuccess}
                onFailure={onFailure}
                customizeErrorMsgCallback={customizeErrorMsgCallback}
            />
        </React.Fragment>
    );
}

export default PopOutSourcingInputDoInputButton;
