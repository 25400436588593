/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import layoutConf from "../../config/layoutConf";
import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

import BarcodeTextField from '../../components/input/BarcodeTextField';
import PopProductShipOfOrderBuyerLotList from "./PopProductShipOfOrderBuyerLotList";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function PopProductShipOfOrderBuyerLotCreateDialog(props) {
    const {
        id, grid, schema, process, materialSelect, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, ...others
    } = props;

    const classes = useStyles();

    const { t } = useTranslation();

    const [instance, setInstance] = useState();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [materialByLotSelect, setMaterialByLotSelect] = useState();
    const [dialogDetGrid, setDialogDetGrid] = useState();
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }
        return true;
    }

    const notifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        if (onSuccess) {
            onSuccess(t("success.insertSuccess", { table: "MomSysMaterialByCompany" }));
        }

        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        setKeepOpen((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout(onSuccess(result.data), 0);
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure(t(reason.response.data));
    };

    useEffect(() => {
        if (materialByLotSelect) {
            const shipQty = materialSelect.qty - materialSelect.shipQty;
            let newFormData = {
                orderBuyerId: materialSelect.orderBuyerId,
                shipQty: shipQty,
                mediumUnitShipQty: shipQty * materialByLotSelect.mediumUnitQty / materialByLotSelect.qtyCurrent,
                smallUnitShipQty: shipQty * materialByLotSelect.smallUnitQty / materialByLotSelect.qtyCurrent,
                lotNo: materialByLotSelect.lotNo,
                extLotNo: materialByLotSelect.extLotNo,
                materialCode: materialByLotSelect.materialCode,
                materialCodeVer: materialByLotSelect.materialCodeVer,
                materialName: materialByLotSelect.materialName,
                itemSpecName: materialByLotSelect.itemSpecName,
                itemUnitName: materialByLotSelect.itemUnitName,
                qtyCurrent: materialByLotSelect.qtyCurrent,
                manufacturedDate: materialByLotSelect.manufacturedDate,
                expirationDate: materialByLotSelect.expirationDate,
                wareHouseName: materialByLotSelect.wareHouseName,
            }

            setFormData({...newFormData});
            console.log(materialByLotSelect)
        }
    }, [materialByLotSelect]);

    useEffect(() => {
        formDataClear();
    }, [others.open]);

    const barcodeCallback = (barcode) => {
        if (dialogDetGrid) {
            let loop = dialogDetGrid.rows.length;
            for (let i = 0; i < loop; i++) {
                if (barcode === dialogDetGrid.rows[i]._data.extLotNo) {
                    dialogDetGrid.select(i, 0);
                    return;
                }
            }
        }

        warningLotMaterial();
        return;
    }

    const warningLotMaterial = () => {
        notifyWarning(t("warning.shipmentMaterialNotTheSameLotMaterialCode"));
        formDataClear();
    }

    const formDataClear = () => {
        setFormData(null);
    }

    const isEmpty = (value) => {
        if (value === undefined || value === "undefined" || value === "" || value === null) {
            return true;
        }
        return false;
    }

    const onOk = () => {
        if (isEmpty(formData)) {
            notifyWarning(t('error.insertDataEmpty'));
            return;
        }

        if (isEmpty(formData.lotNo)) {
            notifyWarning(t('error.insertDataEmpty'));
            return;
        }
        save(schema);
    }

    function save(schema) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (formData.shipQty === 0) {
            notifyWarning(t('error.shipQtyCannotBeZero'));
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        let loop = grid.rows.length;
        for (let i = 0; i < loop; i++) {
            let gridData = grid.rows[i]._data;
            if (gridData.lotNo === formData.lotNo) {
                notifyWarning(t("warning.duplicateLotNo"));
                return;
            }
        }

        // build base api url
        let url = "/api/MomSysShipOfOrderBuyerLotTemp/save";
        let apiData = { ...formData };
        let form = new FormData();
        let json = {};
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema) {
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        // 파일정보가 아닌경우 json 추가
        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
            return key;
        }
    }

    function notUndefined(apiData, key) {
        if (apiData !== undefined) {
            return key;
        }
    }

    const onCancel = () => {
        setFormData((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => (!keepOpen))}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{ flexGrow: 1 }}></div>
            </>
        )
    }

    const onChange = (formData) => {
        setFormData(formData);
        setInteraction(true);
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({ ...notification, open: false });
        });
    };

    const onCloseDialog = () => {
        setInteraction(false);
        setFormData((formData) => {
            let newFormData = {
                ...formData
            }
            return newFormData;
        });

        if (onClose) {
            onClose();
        }
    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>
                    {t("dialog.title.momSysShipDetailLotTemp.create")}
                </DialogTitle>
            </DialogHeaderWithClose>
            <Divider />

            <DialogContent style={{ padding: "0px", height: "600px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement>
                        <PopProductShipOfOrderBuyerLotList
                            materialSelect={materialSelect}
                            setDialogDetSelect={setMaterialByLotSelect}
                            setDialogDetGrid={setDialogDetGrid}
                            shipLotGrid={grid}
                        />
                    </ReflexElement>
                    <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />
                    <ReflexElement flex={0.35}>
                        <ReflexContainer orientation="horizontal" >
                            <ReflexElement flex={0.15} data-cy="right-form-master">
                                <div style={{ padding: "16px" }}>
                                    <label>내부 LOT번호</label>
                                    <BarcodeTextField
                                        barcodeCallback={barcodeCallback}
                                        id="BarcodeExtLotNo"
                                    />
                                </div>
                            </ReflexElement>
                            <ReflexSplitter style={{ height: layoutConf.reflex.splitterWidth }} />
                            <ReflexElement data-cy="right-form-detail">
                                <div
                                    style={{
                                        padding: "16px"
                                    }}
                                >
                                    <AutoForm
                                        id="PopProductShipLotCreate"
                                        schema={schema}
                                        mode="edit"
                                        initialData={formData}
                                        onChange={onChange}
                                    />
                                </div>
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>
                </ReflexContainer>
                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>
            <Divider />
            <DialogActionsOkCancel
                firstActionComponent={checkKeepOpen}
                labels={[t("common.add")]}
                buttonDisabled={[!interaction, false]}
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default PopProductShipOfOrderBuyerLotCreateDialog;
