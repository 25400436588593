/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function MomSysLogShip(props) {
    const { onSelectMst, onLogShipApiInitialized, setIsLogData } = props;

    const [refresh, setRefresh] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        if (refresh) {
            refresh.refresh();
        }
    }, [onSelectMst?.shipId]);

    return (
        <BasicCrudScreenType
            id="MomSysZvwShip"
            view="MomSysZvwShip"
            headerTitle={t("term.standardLogShip")}
            onApiInitialized={(api) => {
                if (onLogShipApiInitialized) {
                    onLogShipApiInitialized(api);
                }

                setRefresh(api);
            }}
            onModifyViewUrl={(url) => `${url}/${onSelectMst?.shipId}`}
            noExcel
            onInitialized={(grid) => {
                grid.selectionMode = "None";
            }}
            onLoadData = { (data) => {
                if(setIsLogData) setIsLogData(data.length > 0 ? true : false)
            }}
        />
    );
}

export default MomSysLogShip;
