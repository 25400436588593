import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import notificationState from "../../states/notificationState";
import layoutConf from "../../config/layoutConf";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import getSchema from "../../lib/api/getSchema";
import PopOutSourcingInputDoInputButton from "../input/PopOutSourcingInputDoInputButton";
import PrintReportButton from "../input/PrintReportButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import OutSourcingInputCancelButton from "../input/OutSourcingInputCancelButton";

const id = "VwPopOutSourcingInput";
const table = "PopOutSourcingInput";
function OutSourcingInput(props) {

    const [height, setHeight] = useState(0);
    const { t } = useTranslation();

    const [popOutSourcingInputSchema, setPopOutSourcingInputSchema] = useState();
    const [popVwOutSourcingInputTargetListGrid, setPopVwOutSourcingInputTargetListGrid] = useState();
    const [popVwOutSourcingInputTargetListApi, setPopVwOutSourcingInputTargetListApi] = useState();
    const [popVwOutSourcingInputTargetListSelect, setPopVwOutSourcingInputTargetListSelect] = useState();

    const [outsourcingInputHistoryGrid, setOutsourcingInputHistoryGrid] = useState();
    const [outsourcingInputHistoryApi, setOutsourcingInputHistoryApi] = useState();
    const [outsourcingInputHistorySelect, setOutsourcingInputHistorySelect] = useState();

    const [reportUrl, setReportUrl] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            schema.columns.push({
                name: "attachedDoc",
                formItemType: "file",
                formGroup: 2,
                width: "100%"
            })

            for (let column of schema.columns) {
                switch (column.name) {
                    case "":
                        break;
                    default:
                        break;
                }
            }

            if (mounted) {
                setPopOutSourcingInputSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        const path = `popOutSourcingInputLot/${outsourcingInputHistorySelect?.lotNo}`;
        let url = `/report/itemInfoPrint/${path}`;

        if (process.env.NODE_ENV !== "production") {
            url = "http://localhost:8080" + url;
        }

        setReportUrl(url);
    }, [outsourcingInputHistorySelect]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (popVwOutSourcingInputTargetListGrid && mounted) {
                popVwOutSourcingInputTargetListGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputTargetListSelect([]);
                        }
                    }),
                    10
                );

                popVwOutSourcingInputTargetListGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPopVwOutSourcingInputTargetListSelect(grid.selectedItems[0]);
                        } else {
                            setPopVwOutSourcingInputTargetListSelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [popVwOutSourcingInputTargetListGrid]);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (outsourcingInputHistoryGrid && mounted) {
                outsourcingInputHistoryGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutsourcingInputHistorySelect(grid.selectedItems[0]);
                        } else {
                            setOutsourcingInputHistorySelect([]);
                        }
                    }),
                    10
                );

                outsourcingInputHistoryGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setOutsourcingInputHistorySelect(grid.selectedItems[0]);
                        } else {
                            setOutsourcingInputHistorySelect([]);
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [outsourcingInputHistoryGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const modifyUrlCallback = () => {
        return `/api/OutSourcingInputHistory`
    }

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5} >
                    <BasicCrudScreenType
                        id={id}
                        view="VwPopOutSourcingInput"
                        headerTitle={t("term.popVwOutSourcingInputTargetList")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setPopVwOutSourcingInputTargetListGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setPopVwOutSourcingInputTargetListApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <PopOutSourcingInputDoInputButton
                                        id={id}
                                        schema={popOutSourcingInputSchema}
                                        onSelectMst={popVwOutSourcingInputTargetListSelect}
                                        refreshFtn={() => {
                                            popVwOutSourcingInputTargetListApi.refresh();

                                            if (outsourcingInputHistoryApi) {
                                                outsourcingInputHistoryApi.refresh();
                                            }
                                        }}
                                        modifyUrlCallback={modifyUrlCallback}
                                        disabled={popVwOutSourcingInputTargetListSelect?.length <= 0}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.4} data-cy="History">
                    <BasicCrudScreenType
                        id={"VwPopOutSourcingInputHistory"}
                        view="VwPopOutSourcingInputHistory"
                        headerTitle={t("term.popVwOutSourcingInputHistory")}
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setOutsourcingInputHistoryGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setOutsourcingInputHistoryApi(api);
                        }}
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <React.Fragment>
                                <BasicCrudScreenActionItemLayout>
                                    <OutSourcingInputCancelButton
                                        id="outsoucring-input-cacnel"
                                        grid={outsourcingInputHistoryGrid}
                                        disabled={!outsourcingInputHistorySelect?.lotNo}
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        refresh={() => {

                                            if (popVwOutSourcingInputTargetListApi) {

                                                popVwOutSourcingInputTargetListApi.refresh();
                                            }

                                            if (outsourcingInputHistoryApi) {
                                                outsourcingInputHistoryApi.refresh();
                                            }
                                        }}
                                    >
                                        {t("buttonName.lotInfoPrint")}
                                    </OutSourcingInputCancelButton>
                                    <PrintReportButton
                                        noLayout
                                        url={reportUrl}
                                        disabled={!outsourcingInputHistorySelect?.lotNo}
                                    >
                                        {t("buttonName.lotInfoPrint")}
                                    </PrintReportButton>
                                </BasicCrudScreenActionItemLayout>
                            </React.Fragment>
                        )}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default OutSourcingInput;
