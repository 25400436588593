import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { useState, useEffect, useRef } from "react";
import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import AutoForm from "../form/functional/AutoForm";
import apiClient from "../../lib/common/apiClient";
import { debounce } from "lodash";
import checkValidation from "../../lib/utils/checkValidation";
import { Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import uiConf from "../../config/uiConf";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogTitle from "../dialog/functional/DialogTitle";
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import Button from "../input/Button";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import MigrationInsertTenantCreateDialog from "../dialogTypes/MigrationInsertTenantCreateDialog";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";


const dbSettingsSchema = {

    name: "dbSettings",
    type: "VIEW",
    searchAll: false,

    keys: [

    ],
    useGridColumnOrder: true,
    useFilterFormOrder: true,

    // columnGroups: [
    //     {
    //         name: "baseDate",
    //     },
    // ],

    columns: [
        {
            name: "host",
            defaultValue: 'localhost',
            formItemType: "text",
            required : true,
        },
        {
            name: "port",
            defaultValue: 3306,
            formItemType: "number",
            required : true,
        },
        {
            name: "id",
            defaultValue: 'asf',
            formItemType: "text",
            required : true,
        },
        {
            name: "password",
            defaultValue: '',
            formItemType: "text",
            required : true,
        },
    ]
};




function MigrationInsert ( props ) {
    const { t } = useTranslation ();
    
    const [height, setHeight] = useState(0);

    const [tenantGrid, setTenantGrid] = useState();
    const [tenantGridSelect, setTenantGridSelect] = useState();
    
    const [actionGrid, setActionGrid] = useState();
    const [actionGridSelect, setActionGridSelect] = useState();
    

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    const [tarHost, setTarHost] = useState('');

    const [tenants, setTenants] = useState([])
    const [srcTenants, setSrcTenants] = useState([])
    const [targetTenant, setTargetTenant] = useState('')


    const [actions, setActions] = useState([])
    
    

    const [tenantOpen, setTenantOpen] = useState(false);
    const [tenantFormData, setTenantFormData] = useState();
    const [dbSettingFormData, setDbSettingFormData] = useState();
    
    const [migratinInsertTenantSchema, setMigratinInsertTenantSchema] = useState(
        {
            name: "tenantSettings",
            type: "VIEW",
            searchAll: false,
            filterFormGroups : [
                "value",
                "range",
            ],
            useGridColumnOrder: true,
            useFilterFormOrder: true,
        
            columns: [
                {
                    name: "srcTenantName",
                    default:'',
                    formItemType: 'select',
                    selectItems:[],
                    required: true,
                    grid : {
                        columnOrder:2
                    },
                    // doSearch: true
        
                },
                {
                    name: "targetTenantName",
                    default: '',
                    formItemType: "text",
                    required : true,
                    grid: {
                        columnOrder : 12,
                    }
                },
            ]
        }
    );
    
    const [migratinInsertActionSchema, setMigratinInsertActionSchema] = useState(
        {
            name: "migrationInsertAction",
            type: "VIEW",
            searchAll: false,
            filterFormGroups : [
                "value",
                "range",
            ],
            useGridColumnOrder: true,
            useFilterFormOrder: true,
        
            columns: [
                {
                    name: "type",
                    default:'',
                    formItemType: 'text',
                    grid : {
                        columnOrder:1
                    },
                },
                {
                    name: "srcHost",
                    default:'',
                    formItemType: 'text',
                    grid : {
                        columnOrder:11,
                    },
                },
                {
                    name: "srcTenantId",
                    default:'',
                    formItemType: 'text',
                    grid : {
                        columnOrder:12,
                        hide: true
                    },
                },
                {
                    name: "srcTenantName",
                    default: '',
                    formItemType: "text",
                    grid: {
                        columnOrder : 13,
                    }
                },
                {
                    name: "targetHost",
                    default:'',
                    formItemType: 'text',
                    grid : {
                        columnOrder:21,

                    },
                },
                {
                    name: "targetTenantId",
                    default:'',
                    formItemType: 'text',
                    grid : {
                        columnOrder: 22,
                        hide: true
                    },
                },
                {
                    name: "targetTenantName",
                    default: '',
                    formItemType: "text",
                    grid: {
                        columnOrder : 23,
                    }
                },
            ]
        }
    );


    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 144);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();
        

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    useEffect(() => {
        let url = `/api/migrationinsert/getTarHost`;
          
        let formData = new FormData ();

        apiClient
            .get(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => {
                
                setTarHost(result.data.data)
                
            })
            .catch((reason) => onApiFailure(reason));
    }, )

    const onInitialized = grid => {
        setTenantGrid(grid);
    };

    const onActionInitialized = grid => {
        setActionGrid(grid);
    }

    useEffect(() => {
        (async () => {
            if (tenantGrid) {
                tenantGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (tenantGrid.selectedItems && tenantGrid.selectedItems[0]) {
                            
                            setTenantGridSelect(tenantGrid.selectedItems[0]);
                            
                            
                        } else {
                            
                            setTenantGridSelect();
                            
                        }
                    }, 0)
                );

                tenantGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setTenantGridSelect(grid.selectedItems[0])
                        

                    } else {

                        setTenantGridSelect();
                        

                    };

                }, 0));

                
            }
        })();
    }, [tenantGrid]);

    useEffect(() => {
        (async () => {
            if (actionGrid) {
                actionGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (actionGrid.selectedItems && actionGrid.selectedItems[0]) {
                            
                            setActionGridSelect(actionGrid.selectedItems[0]);
                            
                            
                        } else {
                            
                            setActionGridSelect();
                            
                        }
                    }, 0)
                );

                actionGrid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setActionGridSelect(grid.selectedItems[0])
                        

                    } else {

                        setActionGridSelect();
                        

                    };

                }, 0));

                
            }
        })();
    }, [actionGrid]);

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.notFound", 
            { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

        

    const onDbSettingsChange = ( formData ) => {

        setDbSettingFormData(formData)
    }

    const onTenantOpen = () => {
        if (checkItemValidataion(dbSettingsSchema, dbSettingFormData )) {

            let url = `/api/migrationinsert/getTenants`;
            let apiData = dbSettingFormData;
                
            let formData = new FormData ();
            let json = apiData;
            let deleted = {};

            formData.append ( "deleted", JSON.stringify ( deleted ) );
            formData.append ( "json", JSON.stringify ( json ) );


            apiClient
                .post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => {
                    setSrcTenants(result.data.data.src)
                    // setTargetTenants(result.data.data.target)

                    let schemaInfo = migratinInsertTenantSchema;
                    for (let column of schemaInfo.columns) {
        
                        switch (column.name) {
                
                            case "srcTenantName":
                
                                column.selectItems = result.data.data.src
                
                                break;
                            case "targetTenantName":
                
                                // column.selectItems = result.data.data.target
                
                                break;
                        }
                    }
                    
                    setMigratinInsertTenantSchema({
                        ...migratinInsertTenantSchema, columns: schemaInfo.columns
                    })

                    setTenantOpen(true);
                })
                .catch((reason) => onApiFailure(reason));

        }
    };

    const onTenantClose = () => {
        setTenantOpen(false);
    };

    const onCreateSuccess = (tenantData) => {
        const srcTenant = srcTenants.filter(item => item.value === tenantData.srcTenantName)[0]
        const targetTenant = tenantData.targetTenantName


        const isTenant = tenants.filter(item => item.srcTenantId === srcTenant.value  && item.targetTenantName === targetTenant)
        if (isTenant.length > 0) return;
        
        setTenants([...tenants,
            {
                srcTenantId: srcTenant.value,
                srcTenantName: srcTenant.name,
                targetTenantId: 0, //targetTenant.value,
                targetTenantName: tenantData.targetTenantName, //targetTenant.name,
            }
        ])
    };

    const deleteTenant = () => {
        if (!tenantGridSelect) return;
        setTenants(tenants.filter(item => 
            !(item.srcTenantId === tenantGridSelect.srcTenantId && item.targetTenantName === tenantGridSelect.targetTenantName))
        )
    }

    const deleteAction = () => {
        if (!actionGridSelect) return;

        setActions(actions.filter(item => 
            !(item.type === actionGridSelect.type && 
                item.srcTenantId === actionGridSelect.srcTenantId &&
                item.targetTenantName === actionGridSelect.targetTenantName))
        )
    }

    const addMigrationAction = (type) => {
        if (!checkItemValidataion(dbSettingsSchema, dbSettingFormData )) return
        

        const srcTenant = srcTenants.filter(item => item.value === tenantGridSelect.srcTenantId)[0]
        const targetTenant = tenantGridSelect.targetTenantName
        let addRow = {};
        let addRows = [];
        let isAction = []

        switch(type) {
            case 1: // menu
                isAction = actions.filter(item => item.type === 'menu')
                if (isAction.length > 0) {
                    nofifyWarning('already added');
                    break;
                } 
        
                addRow = {
                    type: 'menu',
                    srcHost: dbSettingFormData.host,
                    srcTenantId: '-',
                    srcTenantName: '-',
                    targetHost: tarHost,
                    targetTenantId: '-',
                    targetTenantName: '-'
                }
                setActions([...actions,
                    addRow
                ])
                break;

            case 2: // localization
                if (!tenantGridSelect) {
                    nofifyWarning('Tenant is not selected');
                    break;
                }
                isAction = actions.filter(item => item.type === 'localization' 
                    && item.srcTenantId === '-' && item.targetTenantId === '-')
                if (isAction.length === 0) {
                    
                    addRow = {
                        type: 'localization',
                        srcHost: dbSettingFormData.host,
                        srcTenantId: '-',
                        srcTenantName: '-',
                        targetHost: tarHost,
                        targetTenantId: '-',
                        targetTenantName: '-'
                    }
                    addRows.push(addRow)
                }
                
                isAction = []
                isAction = actions.filter(item => item.type === 'localization' 
                    && item.srcTenantId === srcTenant.value  && item.targetTenantName === targetTenant.value)
                if (isAction.length === 0) {
                    
                    addRow = {
                        type: 'localization',
                        srcHost: dbSettingFormData.host,
                        srcTenantId: srcTenant.value,
                        srcTenantName: srcTenant.name,
                        targetHost: tarHost,
                        targetTenantId: 0,
                        targetTenantName: targetTenant
                    }
                    addRows.push(addRow)
                }

                if (addRows.length > 0) {
                    setActions([...actions,
                        ...addRows
                    ])
                }
                break;

            case 3: // options
                if (!tenantGridSelect) {
                    nofifyWarning('Tenant is not selected');
                    break;
                }
                isAction = actions.filter(item => item.type === 'options' 
                    && item.srcTenantId === '-' && item.targetTenantId === '-')
                if (isAction.length === 0) {
                    addRow = {
                        type: 'options',
                        srcHost: dbSettingFormData.host,
                        srcTenantId: '-',
                        srcTenantName: '-',
                        targetHost: tarHost,
                        targetTenantId: '-',
                        targetTenantName: '-'
                    }
                    addRows.push(addRow)
                }

                isAction = []
                isAction = actions.filter(item => item.type === 'options' 
                    && item.srcTenantId === srcTenant.value && item.targetTenantId === targetTenant.value)
                if (isAction.length === 0) {
                    addRow = {
                        type: 'options',
                        srcHost: dbSettingFormData.host,
                        srcTenantId: srcTenant.value,
                        srcTenantName: srcTenant.name,
                        targetHost: tarHost,
                        targetTenantId: 0,
                        targetTenantName: targetTenant
                    }
                    addRows.push(addRow)
                }

                if (addRows.length > 0) {
                    setActions([...actions,
                        ...addRows
                    ])
                }
                break;
            default:
                break;
        }
    }

    const createScript = () => {
        if (!checkItemValidataion(dbSettingsSchema, dbSettingFormData )) return
        if (actions.length === 0) return;

        let url = `/api/migrationinsert/createScript`;
            let apiData = {
                dbInfo:dbSettingFormData,
                migrationData: actions
            }
            
            let formData = new FormData ();
            let json = apiData;
            let deleted = {};

            formData.append ( "deleted", JSON.stringify ( deleted ) );
            formData.append ( "json", JSON.stringify ( json ) );


            apiClient
                .post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => {
                    // file download
                    const blob = new Blob ( [result.data] );
                    const link = document.createElement ( "a" );

                    link.href = window.URL.createObjectURL ( blob );
                    link.download = "insertUpdateScript.js";
                    link.click ();

                    window.URL.revokeObjectURL ( link.href );

                })
                .catch((reason) => onApiFailure(reason));
    }
    
    return (
        <div style={{ height: height}}>
            <div style={{height:'40px', backgroundColor:'white'}}>
                <BasicCrudScreenActionItemLayout>
                    <Button 
                        id="menuMigration" 
                        color="primary" 
                        onClick={() => addMigrationAction(1)}
                    >
                        {t("buttonName.menuMigration")}
                    </Button>


                    <Button 
                        id="localizationMigration" 
                        color="primary" 
                        onClick={() => addMigrationAction(2)}
                    >
                        {t("buttonName.localizationMigration")}
                    </Button>

                    <Button 
                        id="optionsMigration" 
                        color="primary" 
                        onClick={() => addMigrationAction(3)}
                    >
                        {t("buttonName.optionsMigration")}
                    </Button>

                    <Button 
                        id="migrationScriptCreate" 
                        color="primary" 
                        onClick={() => createScript()}
                    >
                        {t("buttonName.scriptCreate")}
                    </Button>

                    
                </BasicCrudScreenActionItemLayout>
            </div>

            <ReflexContainer  orientation="vertical">

                <ReflexElement  flex={0.4} >
                    <ReflexContainer orientation="horizontal">
                        <ReflexElement >                
                            <DialogHeader>
                                <DialogTitle>
                                    { t ( "term.dbsettings" ) }
                                </DialogTitle>
                            </DialogHeader>

                            <Divider />
                                
                            <AutoForm
                                style={{marginTop:'20px', marginLeft:'2%', marginRight:'2%'}}
                                id="miagrationInsert-dbsettings-form"
                                schema={dbSettingsSchema}
                                mode="create"
                                initialData={dbSettingFormData}
                                onChange={onDbSettingsChange}
                            />

                        </ReflexElement>

                        <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                        <ReflexElement>
                            <CreateRecordButtonLayout id={'migrationInsertTenant-append-button'} onClick={onTenantOpen} />
                            
                            

                            <MigrationInsertTenantCreateDialog
                                id={"migrationInsertTenant-append-dialog"}
                                title={t(`dialog.title.migrationInsertTenant.create`)}
                                schema={migratinInsertTenantSchema}
                                open={tenantOpen}
                                onClose={onTenantClose}
                                initialData={tenantFormData}
                                onSuccess={onCreateSuccess}
                            />

                                <Button 
                                id="DeleteMigigrainInsertTenant" 
                                color="secondary" 
                                // style={{float: "left"}} 
                                onClick={() => deleteTenant()}
                            >

                                {t("buttonName.delete")}
                            </Button>
                            
                            <FlexGrid 
                                itemsSource={tenants} // 데이터를 FlexGrid에 전달
                                initialized={onInitialized}
                                selectionMode="Row"
                                autoGenerateColumns={false}
                                headersVisibility='Column'
                                isReadOnly={true}
                                columns={[
                                    { binding: 'srcTenantId', header: 'srcTenantId', visible: false },
                                    { binding: 'srcTenantName', header: 'SrcTenantName',  width:"*"},
                                    { binding: 'targetTenantId', header: 'targetTenantId', visible: false},
                                    { binding: 'targetTenantName', header: 'targetTenantName',  width:"*" }
                                ]}
                                
                            />
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
                
                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />
                
                <ReflexElement>

                    <Button 
                        id="DeleteMigigrainInsertTenant" 
                        color="secondary" 
                        onClick={() => deleteAction()}
                    >
                        {t("buttonName.delete")}
                    </Button>
                    <FlexGrid 
                        itemsSource={actions} // 데이터를 FlexGrid에 전달
                        initialized={onActionInitialized}
                        selectionMode="Row"
                        autoGenerateColumns={false}
                        headersVisibility='Column'
                        isReadOnly={true}
                        columns={[
                            { binding: 'type', header: 'type' },
                            { binding: 'srcHost', header: 'srcHost'},
                            { binding: 'srcTenantId', header: 'srcTenantId', visible: false },
                            { binding: 'srcTenantName', header: 'SrcTenantName',  width:"*"},
                            { binding: 'targetHost', header: 'targetHost'},
                            { binding: 'targetTenantId', header: 'targetTenantId', visible: false},
                            { binding: 'targetTenantName', header: 'targetTenantName',  width:"*" }
                        ]}
                    />

                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert
                            onClose={onCloseNotification}
                            variant="filled"
                            severity={notification.severity}
                        >

                            {notification.msg}

                        </Alert>

                    </Snackbar>

                </ReflexElement>

            </ReflexContainer>
        </div>
    );

}

export default MigrationInsert;
