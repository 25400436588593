import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import uiConf from "../../config/uiConf";

import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import AutoForm from "../form/functional/AutoForm";

import apiClient from "../../lib/common/apiClient";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { Snackbar } from "@material-ui/core";
import layoutConf from "../../config/layoutConf";
import OrderPurchaseDetailTarget from "../screens/OrderPurchaseDetailTarget";
import OrderPurchaseMstTarget from "../screens/OrderPurchaseMstTarget";
import { CollectionView } from "@grapecity/wijmo";
import OrderPurchaseDetailInputList from "../screens/OrderPurchaseDetailInputList";
import FormItemFile from "../form/input/FormItemFile";
import { Alert } from "@material-ui/lab";
import getExpirationDate from "../../lib/utils/getExpirationDate";

const useStyles = makeStyles((theme) => ({
  stepper: {
    margin: "0px",
    padding: "0px",
  },

  label: {
    fontSize: "smaller",
    color: theme.palette.primary.text,
  },

  hidden: {
    position: "absolute",
    transform: "scale(0)",
    opacity: 0,
    pointerEvents: "none",
  },

  addButton: {
    float: "right",
  },

  stepButtons: {
    textAlign: "center",
  },
}));

const steps = ["발주 선택", "입고 데이터 입력 및 선택", "첨부파일 등록"];

function OrderPurchaseInputDialog(props) {
  const {
    id,
    schema,
    moveable,
    resizable,
    fullWidth,
    maxWidth,

    onClose,
    onInitialized,
    onSuccess,

    title,
    formId,

    onDialogUpdateDetail,
    onSelectMst,
    modifyUrlCallback,

    customizeErrorMsgCallback,

    ...others
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [fileData, setFileData] = useState({
    activeFiles: [],
    deletedFiles: [],
  });

  const [instance, setInstance] = useState();

  const classes = useStyles();
  const { t } = useTranslation();

  const [orderPurchaseSelect, setOrderPurchaseSelect] = useState();

  const [orderPurchaseDetailSelect, setOrderPurchaseDetailSelect] = useState();

  const [inputListGrid, setInputListGrid] = useState();

  const [notification, setNotification] = useState({
    open: false,
    severity: "success",
    msg: "",
  });

  useEffect(() => {
    setFormData((prevFormData) => {
      let newFormData = {
        ...prevFormData,
        qty: +orderPurchaseDetailSelect?.qty,
        inputDate: new Date(),
        price: orderPurchaseDetailSelect?.unitCost,
        amount:
          orderPurchaseDetailSelect?.qty * orderPurchaseDetailSelect?.unitCost,
        manufacturedDate: new Date(),
        expirationDate: getExpirationDate(
          orderPurchaseDetailSelect?.standardExpirationDate,
          new Date()
        ),
      };

      return newFormData;
    });
  }, [orderPurchaseDetailSelect]);

  useEffect(() => {
    if (inputListGrid) {
      inputListGrid.itemsSource = new CollectionView([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderPurchaseSelect]);

  const handleNext = (
    schema,
    orderPurchaseSelect,
    orderPurchaseDetailSelect,
    inputListGrid,
    fileData
  ) => {
    switch (activeStep) {
      case 0:
        if (orderPurchaseSelect.length <= 0) {
          nofifyWarning(t("warning.emptyOrderPurchase"));

          return;
        } else {
          setActiveStep((prevStep) => prevStep + 1);
        }

        break;
      case 1:
        if (inputListGrid?.itemsSource?.items?.length <= 0) {
          nofifyWarning(t("warning.emptyInputMaterial"));
          return;
        } else {
          setActiveStep((prevStep) => prevStep + 1);
        }
        break;
      case 2:
        onOk(
          schema,
          orderPurchaseSelect,
          orderPurchaseDetailSelect,
          inputListGrid,
          fileData
        );

        break;
      default:
        setActiveStep((prevStep) => prevStep + 1);
        break;
    }
  };

  const notifySuccess = (msg) => {
    setNotification({
      severity: "success",
      msg: msg,
      open: true,
    });
  };

  const nofifyWarning = (msg) => {
    setNotification({
      severity: "warning",
      msg: msg,
      open: true,
    });
  };

  const notifyFailure = (msg) => {
    setNotification({
      severity: "error",
      msg: msg,
      open: true,
    });
  };

  const onNotifySuccess = async (msg) => {
    notifySuccess(msg);
  };

  const onDialogInitialized = (instance) => {
    setInstance(instance);

    if (onInitialized) {
      onInitialized(instance);
    }
  };

  const onApiSuccess = (result) => {
    onNotifySuccess(result.data);

    if (onSuccess) {
      setTimeout(onSuccess(result.data), 0);
    }

    instance.hide();
  };

  const onApiFailure = (reason) => {
    if (customizeErrorMsgCallback) {
      notifyFailure(customizeErrorMsgCallback(reason));
    } else {
      notifyFailure(
        t("error.insertFailure", {
          reason: reason.response
            ? reason.response.data
            : JSON.stringify(reason),
        })
      );
    }
  };

  const checkItemValidataion = (schema, formData) => {
    for (const column of schema.columns) {
      let [valid, msg] = checkValidation(
        "create",
        column,
        column.validation,
        formData[column.name] || ""
      );
      if (!valid) {
        nofifyWarning(msg);
        return false;
      }
    }

    return true;
  };

  const onChangeFormData = (formData) => {
    setFormData(formData);
  };
  const onChangeFileData = (fileData) => {
    setFileData(fileData);
  };

  const onCloseDialog = () => {
    setFormData({});
    setActiveStep(0);

    if (onClose) {
      onClose();
    }
  };

  const onCloseNotification = () => {
    setNotification((notification) => {
      return { ...notification, open: false };
    });
  };

  const onOk = (
    schema,
    orderPurchaseSelect,
    orderPurchaseDetailSelect,
    inputListGrid,
    fileData
  ) => {
    if (inputListGrid?.itemsSource?.items?.length <= 0) {
      nofifyWarning(t("warning.emptyInputMaterial"));
      return;
    }

    let url = `/api/OrderPurchaseInputMst`;
    let apiData = {
      ...orderPurchaseSelect,
      ...orderPurchaseDetailSelect,
      inputMaterials: [...inputListGrid?.itemsSource?.items],
      attachedFiles: {
        activeFiles: [...fileData?.activeFiles],
        deletedFiles: [...fileData?.deletedFiles],
      },
    };
    let form = new FormData();

    let json = {};
    let deleted = {};

    if (schema && !schema.columnMap) {
      let columnMap = {};
      schema.columns.map((column) => (columnMap[column.name] = column));
      schema.columnMap = columnMap;
    }

    for (const formItem in apiData) {
      if (
        schema.columnMap &&
        schema.columnMap[formItem] &&
        schema.columnMap[formItem].formItemType === "file"
      ) {
        deleted[formItem] = apiData[formItem].deletedFiles;
        for (const file of apiData[formItem].activeFiles) {
          if (file.type === "url") continue;
          form.append(formItem, file.file);
        }
      } else {
        if (apiData[formItem] !== undefined) {
          json[formItem] = apiData[formItem];
        }
      }
    }

    form.append("deleted", JSON.stringify(deleted));
    form.append("json", JSON.stringify(json));

    apiClient
      .post(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => onApiSuccess(result))
      .catch((reason) => onApiFailure(reason));
  };

  const handleAddRow = (
    inputListGrid,
    orderPurchaseDetailSelect,
    formData,
    schema
  ) => {
    if (!checkItemValidataion(schema, formData)) {
      return;
    }

    if (schema.validation) {
      let [valid, msg] = checkConditionWithMsg(formData, schema.validation);
      if (!valid) {
        nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
        return;
      }
    }

    if (inputListGrid.itemsSource) {
      for (let item of inputListGrid.itemsSource.items) {
        if (
          item.materialCode === orderPurchaseDetailSelect.materialCode &&
          item.materialCodeVer === orderPurchaseDetailSelect.materialCodeVer
        ) {
          nofifyWarning(t("warning.existedInputMaterial"));
          return;
        }
      }
    }

    let itemsSource = inputListGrid.itemsSource;

    const newRow = {
      materialName: orderPurchaseDetailSelect.materialName,
      materialCode: orderPurchaseDetailSelect.materialCode,
      materialCodeVer: orderPurchaseDetailSelect.materialCodeVer,
      ...formData,
    };

    if (itemsSource instanceof CollectionView) {
      itemsSource.addNew(newRow);
      itemsSource.commitNew();
    } else {
      const newData = [
        ...(Array.isArray(itemsSource) ? itemsSource : []),
        newRow,
      ];
      inputListGrid.itemsSource = new CollectionView(newData);
    }
  };

  return (
    <Dialog
      id={id}
      moveable={moveable ? moveable : uiConf.dialogMoveable}
      resizable={resizable ? resizable : uiConf.dialogResizable}
      fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
      fullScreen
      onClose={onCloseDialog}
      onInitialized={onDialogInitialized}
      {...others}
    >
      <DialogHeaderWithClose>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeaderWithClose>
      <DialogContent style={{ padding: "0px", height: "900px" }}>
        <ReflexContainer orientation="horizontal">
          <ReflexElement flex={0.15}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.stepButtons}>
              <Button
                disabled={activeStep === 0}
                color="secondary"
                onClick={() => setActiveStep((prevStep) => prevStep - 1)}
              >
                {t("common.before")}
              </Button>
              <Button
                onClick={() =>
                  handleNext(
                    schema,
                    orderPurchaseSelect,
                    orderPurchaseDetailSelect,
                    inputListGrid,
                    fileData
                  )
                }
                color="primary"
              >
                {activeStep === steps.length - 1
                  ? t("common.complete")
                  : t("common.next")}
              </Button>
            </div>
          </ReflexElement>
          <ReflexSplitter
            style={{ height: layoutConf.reflex.splitterHeight }}
          />
          <ReflexElement flex={0.85}>
            {/* OrderPurchaseMstTarget - Step 0 */}
            <div className={activeStep === 0 ? "" : classes.hidden}>
              <OrderPurchaseMstTarget setOnSelect={setOrderPurchaseSelect} />
            </div>
            <ReflexContainer
              orientation="vertical"
              className={activeStep === 1 ? "" : classes.hidden}
            >
              {/* Detailed View - Step 1 */}
              <ReflexElement flex={0.4}>
                <ReflexContainer orientation="horizontal">
                  <ReflexElement flex={0.4}>
                    <OrderPurchaseDetailTarget
                      onSelect={orderPurchaseSelect}
                      setOrderPurchaseDetailSelect={
                        setOrderPurchaseDetailSelect
                      }
                    />
                  </ReflexElement>
                  <ReflexSplitter
                    style={{ height: layoutConf.reflex.splitterHeight }}
                  />
                  <ReflexElement flex={0.6}>
                    <AutoForm
                      id="orderpurchase-input-create-form"
                      schema={schema}
                      mode="edit"
                      initialData={formData}
                      onChange={onChangeFormData}
                      modifyFormDataCallback={(formData, name) => {
                        if (name === "manufacturedDate") {
                          formData.expirationDate = getExpirationDate(
                            orderPurchaseDetailSelect.standardExpirationDate,
                            new Date(formData.manufacturedDate)
                          );
                        }
                      }}
                    />
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() =>
                        handleAddRow(
                          inputListGrid,
                          orderPurchaseDetailSelect,
                          formData,
                          schema
                        )
                      }
                    >
                      {t("common.add")}
                    </Button>
                  </ReflexElement>
                </ReflexContainer>
              </ReflexElement>
              <ReflexSplitter
                style={{ width: layoutConf.reflex.splitterWidth }}
              />
              <ReflexElement flex={0.6}>
                <OrderPurchaseDetailInputList
                  onInitialized={setInputListGrid}
                />
              </ReflexElement>
            </ReflexContainer>

            <div
              className={activeStep === 2 ? "" : classes.hidden}
              style={{ height: "100%" }}
            >
              <FormItemFile
                onChange={onChangeFileData}
                column={{
                  name: "attachedFiles",
                  columnOrder: 1,
                  type: "multiFileDownload",
                  maxFiles: 10,
                  width: "100%",
                  height: "100%",
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "large",
                    fontWeight: "bold",
                  },
                }}
              />
            </div>
          </ReflexElement>
        </ReflexContainer>
        <Snackbar
          open={notification.open && notification.severity !== "success"}
          autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
          onClose={onCloseNotification}
        >
          <Alert
            onClose={onCloseNotification}
            variant="filled"
            severity={notification.severity}
          >
            {notification.msg}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
}

export default OrderPurchaseInputDialog;
