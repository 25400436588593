/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGrid, FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import { Divider } from "@material-ui/core";

import Dialog from "../../dialog/functional/Dialog";
import DialogContent from "../../dialog/functional/DialogContent";
import DialogTitle from "../../dialog/functional/DialogTitle";

import DialogHeaderWithClose from "../../dialog/functional/DialogHeaderWithClose";
import AutoDataGridColumn from "../functional/AutoDataGridColumn";
import parseS3FileName from "../../../lib/utils/parseS3FileName";

// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridMultiFileDownloadColumn ( props ) {

    const { t } = useTranslation();

    const { schema, ...others } = props;

    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );
    const [ , setInstance ] = useState ();
    const [ items, setItems ] = useState ([]);

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {

            const data = ctx.item[schema.name];

            if ( !data ) return;

            return (
                <>

                    <span onClick={ () => handleOpen (data)}>

                        <IconButton size="small" sx={{p: 0, m: 0}}>

                            <Icon>folder</Icon>

                        </IconButton>

                    </span>

                </>
            )

        } ) );

    }, [] );

    const handleOpen = (data) => {

        setItems(parseUrl(data));

        setInstance((instance) => {
            if(instance) {
                instance.show();
            }
            return instance;
        })
    };

    const onDialogInitialized = (instance) => {

        setInstance(instance);

    };

    return (
        <>
        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
            <Dialog 
                id={"multiFileDownload"} 
                onInitialized={onDialogInitialized}
            >
                <DialogHeaderWithClose>
                    <DialogTitle>{t("dialog.title.fileDownload.multiFile")}</DialogTitle>
                </DialogHeaderWithClose>
                
                <Divider />

                <DialogContent style={{ padding: "0px", height: "500px" }}>
                    <FlexGrid 
                        itemsSource={items}
                        {...others}
                    >
                        {
                            makeSchemaColumns().map((column) => <AutoDataGridColumn key={column.name} schema={column} />)
                        }
                    </FlexGrid>
                </DialogContent>
            </Dialog>
        </DataGridColumn>
        
        </>
    );

}

function parseUrl(data) {

    const items = [];

    if(data.charAt(0) === "/") {

        items.push( { 

            fileName : parseS3FileName(data),
            url : data

        } );

    } else {

        for(const d of JSON.parse(data)) {

            items.push( { 
                
                fileName : parseS3FileName(d),
                url : d
            
            } );

        }
    }

    return items;

}

function makeSchemaColumns() {

    return [
        {
            name: "fileName",
            type: "varchar(255)",
            columnOrder: 10,
        },
        {
            name: "url",
            type: "varchar(255)",
            columnOrder: 20,
            grid: {
                type: "fileDownload",
                isReadOnly: true,
            }
        }
    ]
}

export default DataGridMultiFileDownloadColumn;

