/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";

import "react-reflex/styles.css";

import layoutConf from "../../config/layoutConf";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";
import { Grid, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Search } from "@material-ui/icons";
import { debounce } from 'lodash';
import VwTopMenuItems from "./VwTopMenuItems";
import MenuMigrationCreateQueryButton from "../input/MenuMigrationCreateQueryButton";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import MenuMigrationClearQueryButton from "../input/MenuMigrationClearQueryButton";
import MenuMigrationExportQueryButton from "../input/MenuMigrationExportQueryButton";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import VwSubMenuItems from "./VwSubMenuItems";

const useStyle = makeStyles((theme) => ({
    root: {
        paddingTop: "8px",
        width: "120px"
    },
    
    button : {
        paddingTop: "12px",
    }

}));

function MenuDeleteMigrationView(props) {

    const {tab, onSearchInitialized, onInitialized, onLoadData } = props;

    switch (tab) {

        case 0:

        return (

            <VwTopMenuItems 
                view = "VwTopMenuItems" 
                onSearchInitialized={onSearchInitialized}
                onInitialized= {onInitialized}
                onLoadData = {onLoadData}
            />
            );

        case 1:

        return (
            <VwSubMenuItems 
                view = "VwSubMenuItems" 
                onSearchInitialized={onSearchInitialized}
                onInitialized = {onInitialized}
                onLoadData ={onLoadData}
            />
            );
        default:
            return <></>;
    }
}

function MenuDeleteMigration(props) {

    const classes= useStyle();

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [tab, setTab] = useState(0);

    const [ , setSearch ] = useState ();
    const [ , setSearchVal ] = useState ();

    const [grid, setGrid] = useState();

    const [selectedItem, setSelectedItem] = useState();

    const [querys, setQuerys] = useState("");

    const [isEmpty, setIsEmpty] = useState();

    const syntaxRef = useRef();
 
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectedItem(grid.selectedItems[0]);
                        } else {
                            setSelectedItem([]);
                        }
                    }),
                    10
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setSelectedItem(grid.selectedItems[0]);
                        } else {
                            setSelectedItem([]);
                        }
                    }),
                    10
                );
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);

    }, []);

    const onTabChanged = (tab) => {

        setTab(tab);

    };

    const onChangeSearch = ( e ) => {

        setSearch ( ( search ) => {

            if ( search ) {

                setSearchVal ( e.target.value );
                search.applySearch ( e.target.value );

            }
            return search;

        } );

    };

    const onInitialized = (grid) => {

        grid.selectionMode = "Row";

        setGrid(grid);
    }

    const handleDeleteQuerys = () => {

        if(tab === 0) {

            setQuerys((query) => (query +=`DELETE \nFROM \n\tSubMenuItems \nWHERE \n\t(menuSetId, topMenuItemId) IN (\n\tSELECT \n\t\tvsmi.menuSetId,\n\t\tvsmi.topMenuItemId \n\tFROM \n\t\tVwSubMenuItems vsmi \n\tWHERE \n\t\tvsmi.menuSetName = "${selectedItem.menuSetName}" \n\tAND \n\t\tvsmi.topMenuItemName = "${selectedItem.menuItemName}");\nDELETE \nFROM \n\tTopMenuItems \nWHERE \n\t(menuSetId, topMenuItemId) IN (\n\tSELECT \n\t\tvtmi.menuSetId,\n\t\tvtmi.topMenuItemId \n\tFROM \n\t\tVwTopMenuItems vtmi \n\tWHERE \n\t\tvtmi.menuSetName = "${selectedItem.menuSetName}" \n\tAND \n\t\tvtmi.menuItemName = "${selectedItem.menuItemName}");\n`))
            
        } else {

            setQuerys((query) => (query += `DELETE \nFROM \n\tSubMenuItems \nWHERE \n\t(menuSetId, topMenuItemId, subMenuItemId) IN (\n\tSELECT \n\t\tvsmi.menuSetId,\n\t\tvsmi.topMenuItemId, \n\t\tvsmi.menuItemName \n\tFROM \n\t\tVwSubMenuItems vsmi \n\tWHERE \n\t\tvsmi.menuSetName = "${selectedItem.menuSetName}" \n\tAND \n\t\tvsmi.topMenuItemName = "${selectedItem.topMenuItemName}" \n\tAND \n\t\tvsmi.menuItemName = "${selectedItem.menuItemName}");\n`))
            
        }
        
    }

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onSearchInitialized = ( search ) => {

        setSearch ( search );

        setSearchVal ( ( searchVal ) => {

            if ( searchVal ) {

                search.applySearch ( searchVal );
                
            }

            return searchVal;

        } );

    };

    const exportToFile = () => {
        const content = syntaxRef.current.innerText;
        const blob = new Blob([content], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.href = url;
        a.download = 'DeleteMenuQuery.sql';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (

        <div style={{ height: height }}>

            <ReflexContainer orientation="horizontal" >

            <ReflexElement flex={0.6}>

                <BasicTabScreenType
                    id="MenuMigration"
                    tabs={[0, 1]}
                    tabLabels={["상위 메뉴", "하위 메뉴"]}
                    tabsStyle={{
                        left: 0,
                        margin: "0px",
                        padding:"0px",
                        height: "48px",
                        width: "450px"
                    }}
                    onTabChanged={onTabChanged}
                    value={tab}
                    rightGridItems={
                        [
                            <Grid item>
                                <BasicCrudScreenActionItemLayout>
                                    <MenuMigrationCreateQueryButton
                                        id = "menu-migration-create-query"
                                        onDeleteQuerys = {handleDeleteQuerys}
                                        disabled = {isEmpty}
                                    />
                                    </BasicCrudScreenActionItemLayout>
                            </Grid>,
                            <Grid item>
                                <TextField
                                    id={"menu-migration-search-text"}
                                    className={classes.root}
                                    placeholder={t("common.search")}
                                    onChange={ onChangeSearch }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>                    
                                        )
                                    }}
                                    style={{
                                        bottom: "2px",
                                        width: "120px"                        
                                    }}
                                />
                            </Grid>
                    ]}>
                    <MenuDeleteMigrationView
                        tab = {tab}
                        onSearchInitialized = {onSearchInitialized}
                        onInitialized = {onInitialized}
                        onLoadData = {onLoadData}
                    />
                </BasicTabScreenType>
            </ReflexElement>
            <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />
            <ReflexElement flex={0.4} style={{ overflow: 'hidden', display : 'flex', flexDirection : 'column' }}>
                    <Grid style={{ alignSelf: 'flex-end' }}>
                        <MenuMigrationClearQueryButton
                            id = "menu-migration-clear-query"
                            onClick = {()=> setQuerys("")}
                            disabled = {!querys}
                        />
                        <MenuMigrationExportQueryButton
                            id = "menu-migration-clear-export"
                            onClick = {exportToFile}
                            disabled = {!querys}
                        />
                    </Grid>
                    <Grid item style={{ flexGrow: 1, overflowY: 'auto' }}> 
                        <div
                            style={{
                                height: '100%',
                            }}
                            ref={syntaxRef}
                        >
                        <SyntaxHighlighter
                            language="sql" 
                            style={paraisoLight} 
                            customStyle={{
                                width: "100%",
                                height: "100%", 
                                fontSize: "20px",
                            }}
                        >
                            {querys}
                        </SyntaxHighlighter>
                    </div>
                    </Grid>

            </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default MenuDeleteMigration;
