/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";

import "react-reflex/styles.css";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

function MaterialList ( props ) {

    const { t } = useTranslation ();

    const { product } = props;

    const [ , setApi ] = useState ();

    useEffect ( () => {

        setApi ( ( api ) => {

            if ( api ) {

                api.refresh ();

            }

            return api;

        } );

    }, [product,] );

    const onGridInitialized = ( grid ) => {

        grid.selectionMode = "None";

        if(grid) {
    
            grid.loadedRows.addHandler((flex, e) => {

                let materialMap = {};

                for(const row of flex.rows) {

                    let materialKey = row.dataItem.materialCode+ row.dataItem.materialCodeVer;

                    if(materialMap[materialKey]) {

                        materialMap[materialKey].rows.push(row);
                        materialMap[materialKey].inputQty += row.dataItem.inputQty;

                    } else {

                        materialMap[materialKey] = {
                            rows : [row],
                            materialCode : row.dataItem.materialCode,
                            materialCodeVer : row.dataItem.materialCodeVer,
                            requiredQty : row.dataItem.requiredQty,
                            inputQty : row.dataItem.inputQty,
                        }; 
                    }

                }
                
                for(const key in materialMap) {

                    if(materialMap[key].inputQty === materialMap[key].requiredQty) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-equals-blue`
                        }
                    } else if(materialMap[key].inputQty > materialMap[key].requiredQty) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-many-orange`
                        }
                    }

                    else if(materialMap[key].inputQty < materialMap[key].requiredQty) {

                        for(const row of materialMap[key].rows) {
                            
                            row.cssClass = `qty-little-red`
                        }
                    }

                }

              });
        }
    }

    const onApiInitialized = ( api ) => {

        setApi ( api );

    };

    const onModifyUrl = ( product, url ) => {

        let newUrl;

        if ( process ) {

            newUrl = `${url}/${product?product.planId :null}`;

        }

        return newUrl;
        
    };

    return (

        <BasicCrudScreenType
            id="EguVwPopProductManageDetail"
            headerTitle={t("term.takeOverMaterial")}
            table="EguVwPopProductManageDetail"
            view="EguVwPopProductManageDetail"
            noCreate
            noEdit
            noDelete
            noExcel
            noSearch
            noFilter
            noPagination
            onModifyViewUrl={ ( url ) => onModifyUrl ( product, url ) }
            onInitialized={onGridInitialized}
            onApiInitialized={ onApiInitialized }
        />

    );

};

function EguPopProduceStartDialog ( props ) {

    const { t } = useTranslation ();

    const [instance, setInstance] = useState ();
    const [disabled, setDisabled] = useState ( false );
    const [formData, setFormData] = useState({});
    const [lineNo, setLineNo] = useState();
    const [schema, setSchema] = useState();
    const [qty, setQty] = useState();
    const [process, setProcess] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const {

        id,
        product,
        
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        onClose,
        onInitialized,
        onSuccess,

        isMachineUsing,

        ...others

    } = props;
    
    const checkItemValidataion = ( schema, formData ) => {

        for ( const column of schema.columns ) {

            let [valid, msg] = checkValidation ( "create", column, column.validation, formData[column.name] || "" );

            if ( !valid ) {

                notifyWarning ( msg );
                return false;

            }
            
        }

        if ( formData.qty <= 0 ) {

            notifyWarning ( t ( "error.emptyOrNegativeProductionQty") );
            return false;


        }

        return true;

    }

    const notifyWarning = (msg) => {

        console.warn (msg);

        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });

    };

    const notifyFailure = (msg) => {

        console.error ( msg );

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    useEffect ( () => {

        ( async () => {

            let schema = {

                name: "Production",
                type: "BASE TABLE",
                searchAll: false,
                formGroups: [
                    "materialInfo",
                    "lineNoAndQty"
                ],
                "columns": [
                                    
                    {
                        
                        name: "bopMaterialCode",
                        type: "varchar(50)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: null,
                        formGroup: 0,
                        formOrder: 1,
                        formItemType: "text",
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },
                    {
    
                        name: "bopMaterialCodeVer",
                        type: "int(11)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: "0",
                        formGroup: 0,
                        formOrder: 2,
                        formItemType: "number",
                        validation: {
                            msg: "error.numberRequired",
                            type: "regexp",
                            value: "^[0-9]*$"
                        }
    
                    },
                    {
    
                        name: "bopMaterialName",
                        type: "varchar(50)",
                        nullable: "NO",
                        key: "PRI",
                        required: true,
                        readonly: true,
                        default: null,
                        formGroup: 0,
                        formOrder: 3,
                        formItemType: "text",
                        validation: {
                            msg: "error.inputValueTooLong",
                            type: "length",
                            operator: "le",
                            value: 255
                        }
    
                    },
                    {

                        name: "processName",
                        type: "varchar(50)",
                        nullable: "NO",
                        default: null,
                        readonly: true,
                        formGroup: 0,
                        formOrder: 4,
                        formItemType : "text",
            
                    },    
                    {

                        name: "processCode",
                        type: "varchar(50)",
                        nullable: "NO",
                        default: null,
                        readonly: true,
                        formGroup: 0,
                        formOrder: 5,
                        formItemType : "text",
            
                    },    
                    {
                        name: "lineNo",
                        type: "varchar(50)",
                        nullable: "NO",
                        disabled : true,
                        formGroup: 1,
                        formOrder: 6,
                        formItemType : "text",
                        defaultValue : product?.lineNo,
    
                    },
                    {

                        name: "qty",
                        type: "float",
                        nullable: "NO",
                        key: "",
                        required: true,
                        disabled : true,
                        formGroup: 1,
                        formOrder: 7,
                        formItemType: "float",
                        validation: {
                          msg: "error.floatRequired",
                          type: "regexp",
                          value: "^[+-]?([0-9]*[.])?[0-9]+$"
                        }

                    },
                    {
                        name: "productionStartDate",
                        type: "date",
                        nullable: "NO",
                        formGroup: 1,
                        formOrder: 8,
                        formItemType: "date",
                    }
                ]
    
            };

            setSchema ( schema );
        
            if ( product ) {
                
                setFormData ( ( formData ) => {
    
                    let newFormData = {
                        ...formData,
                        bopMaterialCode: product.bopMaterialCode,
                        bopMaterialCodeVer: product.bopMaterialCodeVer,
                        bopMaterialName: product.materialName,
                        processCode: product.processCode,
                        processName: product.processName,
                        lineNo: product.lineNo,
                        qty: parseFloat(parseFloat(product.plannedQty)),
                    };
    
                    return newFormData;
    
                } );

                setQty ( parseFloat(product.plannedQty));
                setProcess ( product.processCode );
                setLineNo(product.lineNo);

                if(product.processOrder > 1) {

                    ( async ()=>{

                        let beforeTaskProcess = await apiClient.get(`/api/EguVwPopProductManage/process/${product.parentPlanId}/${product.processOrder}`);
                        let beforeTaskProcessResultQty = beforeTaskProcess?.data?.data[0]?.resultQty === undefined ? 0 : beforeTaskProcess?.data?.data[0]?.resultQty;
                        
                        setFormData((formData) => {

                            let newFormData = {
                                ...formData,
                                qty : String(beforeTaskProcessResultQty),
                            }
                            
                            return newFormData

                        })

                        setQty(beforeTaskProcessResultQty);
                        
                    })();

                }
    
            } else {
    
                setFormData ( ( formData ) => {
    
                    let newFormData = {
                        ...formData,
                        bopMaterialCode: undefined,
                        bopMaterialCodeVer: undefined,
                        bopMaterialName: undefined,
                        lineNo: undefined,
                        qty: undefined,
                    };
    
                    return newFormData;
    
                } );
    
                setQty ( undefined );
                setProcess ( undefined );
                setLineNo ( undefined );
    
            }

        } ) ();

    }, [product])

    const onDialogInitialized = ( instance ) => {

        setInstance(instance);

        if ( onInitialized ) {

            onInitialized ( instance );

        }

    };

    const onApiSuccess = (result) => {

        if ( onSuccess ) {

            onSuccess ( t ( "success.insertSuccess", { table: "Production" } ) );

        }

        instance.hide();
        setDisabled ( false );

        if (onSuccess) {

            setTimeout ( onSuccess(result.data), 0 );

        }

    };

    const onApiFailure = (reason) => {

        notifyFailure ( t ( "error.insertFailure", { reason: reason.response ? reason.response.data : JSON.stringify ( reason ) } ) );
        
        setDisabled ( false );

    };

    const onOk = ( schema, product, formData, isMachineUsing ) => {

        if(isMachineUsing) {
            notifyWarning ( t ( "error.machineIsInUse" ) );
            return;
        }

        setDisabled ( true );
        
        if ( ! checkItemValidataion ( schema, formData ) ) {

            console.error ( "Form Validation Failed" );
            setDisabled ( false );
            return;

        }

        if (schema.validation) {

            let [valid, msg] = checkConditionWithMsg(

                formData,
                schema.validation

            );

            if (!valid) {
                notifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                setDisabled ( false );
                return;

            }

        }

        // build base api url

        let url = `/api/Task/${product.planId}/start`;

        let apiData = { ...formData, ...product };
        
        let form = new FormData ();

        let json = {};
        let deleted = {};
        for ( const formItem in apiData ) {

            if(formItem === "plannedWorker" && apiData[formItem] !== null) {

                json[formItem] = [];

                let users = apiData[formItem].split(",");
                for(let user of users) {

                    json[formItem].push(user);
                }

                
            }else if ( apiData[formItem] !== undefined ){

                json[formItem] = apiData[formItem];
            }

        }

        form.append ( "deleted", JSON.stringify ( deleted ) );
        form.append ( "json", JSON.stringify ( json ) );
        
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
            
    }

    const onCancel = () => {

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
            }

            return newFormData;

        } );

        setDisabled ( true );
        instance.hide();

    };

    const onChange = ( formData, newFormData ) => {

        if ( formData.qty !== newFormData.qty ) {

            setQty ( newFormData.qty );

        }

        setFormData ( newFormData );
        setDisabled ( false );

    }

    const onCloseNotification = () => {

        setNotification( ( notification ) => {

            return ({...notification, open: false});

        } );

    };

    const onCloseDialog = () => {

        setDisabled ( false );

        setFormData ( ( formData ) => {

            let newFormData = {
                ...formData,
            }
            delete newFormData?.productionStartDate

            return newFormData;

        } );

        if ( onClose ) {

            onClose ();

        }

    };
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >

            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.Produce.produceStart" ) }
                </DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{padding: "0px", height: "330px"}}>

                <ReflexContainer orientation="vertical">

                    <ReflexElement flex={0.55} >

                        <MaterialList product={product} lineNo={lineNo} qty={qty} process={process} />

                    </ReflexElement>

                    <ReflexSplitter
                        style={{border: "none",width: "1px",color:"lightgray"}}
                    />

                    <ReflexElement flex={0.45} >

                        <div
                            style={{
                                padding: "16px"
                            }}                    
                        >

                            <AutoForm
                                id={id}
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={ ( newFormData ) => onChange( formData, newFormData ) }
                            />

                        </div>

                    </ReflexElement>

                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert
                        onClose={onCloseNotification}
                        variant="filled"
                        severity={notification.severity}
                    >

                        {notification.msg}

                    </Alert>

                </Snackbar>

            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[ t("buttonName.produce")]}
                buttonDisabled={[disabled, false]}
                onOk={() => onOk(schema, product, formData, isMachineUsing)}
                onCancel={onCancel}
            />

        </Dialog>

    );

}

export default EguPopProduceStartDialog;
