/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import apiClient from "../../lib/common/apiClient";

import ConfirmDialog from "../dialogTypes/ConfirmDialog";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import defineConf from "../../config/defineConf";

function OrderPurchaseInputConfirmButton(props) {
    const { t } = useTranslation();

    const { id, schema, grid, title, refresh, onSuccess, onFailure, ...others } = props;

    const [open, setOpen] = useState(false);
    const [confirmMsg, setConfirmMsg] = useState();
    const [confirmData, setConfirmData] = useState();

    const onOpen = () => {
        setConfirmMsg(
            t("dialog.msg.confirm.input", {
                orderPurchaseId: `${grid.selectedItems[0].orderPurchaseId}`,
                orderPurchaseName: `${grid.selectedItems[0].orderPurchaseName}`,
            })
        );
        setConfirmData(grid.selectedItems[0]);

        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onOk = (requestData) => {

        let form = new FormData();
        let json = {};

        if([defineConf.orderPurchaseInputState.NOT_COMPLETED ,defineConf.orderPurchaseInputState.PARTIALLY_COMPLETED].includes(grid.selectedItems[0]?.inputState)) {

            json.inputState = defineConf.orderPurchaseInputState.COMPLETED;
            
        } else {

            json.inputState = defineConf.orderPurchaseInputState.NOT_COMPLETED;

        }
        
        form.append("json", JSON.stringify(json));
        apiClient
            .patch(`/api/MomSysUglOrderPurchaseMst/${grid.selectedItems[0].orderPurchaseId}`,form, {
            })
            .then((result) => {
                if (refresh) {
                    refresh.refresh();
                }
                onSuccess(result.data);
            })
            .catch((reason) => {
                onFailure(t("error.updateFailure", { reason: reason.response ? reason.response.data : reason }));
            });
    };

    return (
        <>
             <CreateRecordButtonLayout 
                id={id+"confirm-button"} 
                onClick={onOpen} 
                {...others}  
                createButtonName = {[defineConf.orderPurchaseInputState.NOT_COMPLETED ,defineConf.orderPurchaseInputState.PARTIALLY_COMPLETED].includes(grid.selectedItems[0]?.inputState) ? "inputComplete" : "inputCancel"} 
                />
            <ConfirmDialog
                id={id + "-input-confirm-dialog"}
                title={schema ? t(`dialog.title.${schema.name}.inputConfirm`) : ""}
                msg={confirmMsg}
                data={confirmData}
                open={open}
                onClose={onClose}
                onOk={onOk}
                onCancel={onClose}
            />
        </>
    );
}

export default OrderPurchaseInputConfirmButton;
