import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguPopProduceStartDialog from "../dialogTypes/EguPopProduceStartDialog";
import apiClient from "../../lib/common/apiClient";

function EguPopProduceStartButton(props) {

    const { t } = useTranslation();
    const { id, onSelectMst, onSuccess, onFailure, onTrigger, ...others } = props;
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(onSelectMst);
    const [isMachineUsing, setIsMachineUsing] = useState(false);

    useEffect(() => {

        setFormData(onSelectMst);

    }, [onSelectMst]);

    const onOpen = async (onSelectMst) => {

        setOpen(true);
        onTrigger(true)

        const isMachineConcurrently = (await apiClient.get('/api/getTenantOptionValue/EguPopProductManage.produceStart/isMachineConcurrently')).data?.optionValue === "true";

        if(!isMachineConcurrently) {

            const machineInfo = (await apiClient.get(`/api/MomSysZvwMachine/${onSelectMst.machineCode}`)).data.data[0];

            setIsMachineUsing(!!machineInfo?.processCode);

        }

    };

    const onClose = () => {

        setOpen(false);
        onTrigger(false)
    };
    const onCreateSuccess = (msg) => {

        if (onSuccess) {

            onSuccess(msg);

        }

    };

    return (

        <>
            <CreateRecordButtonLayout id={`${id}-start-button`} onClick={() => onOpen(onSelectMst)} createButtonName="produceStart" {...others} />
            <EguPopProduceStartDialog
                id={id + "append-dialog"}
                mode="create"
                formId={id + "append-form"}
                product={formData}
                title={t("dialog.title.Produce.startProduction")}
                open={open}
                onClose={onClose}
                onSuccess={onCreateSuccess}
                isMachineUsing={isMachineUsing}
            />
        </>

    );
}

export default EguPopProduceStartButton;

