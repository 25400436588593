/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function OrderPurchaseInputDetail(props) {
  const { onSelectMst } = props;

  const { t } = useTranslation();
  const [api, setApi] = useState();

  useEffect(() => {
    if (api) {
      api.refresh();
    }
  }, [onSelectMst.orderPurchaseInputId]);

  const onModifyUrl = (url, orderPurchaseInputId) => {
    return url + "/" + orderPurchaseInputId;
  };

  return (
    <BasicCrudScreenType
      id="OrderPurchaseInputDetail"
      headerTitle={t("term.orderPurchaseInputDetail")}
      view="VwOrderPurchaseInputDetail"
      noCreate
      noEdit
      noDelete
      noExcel
      onApiInitialized={(api) => setApi(api)}
      onModifyViewUrl={(url) =>
        onModifyUrl(url, onSelectMst?.orderPurchaseInputId)
      }
    />
  );
}

export default OrderPurchaseInputDetail;
