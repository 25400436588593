import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useTranslation } from "react-i18next";
import getSchema from "../../lib/api/getSchema";
import CrudScreenActionItemLayoutByAccessType from "../screenTypes/layout/CrudScreenActionItemLayoutByAccessType";
import MomSysProductBackShipButton from "../input/MomSysProductBackShipButton";
import MomSysBackShipCancelButton from "../input/MomSysBackShipCancelButton";
import { debounce } from "lodash";

function MomSysProductBackShip ( props ) {

    const { accessType } = props;

    const { t } = useTranslation();

    const id = "MomSysProductBackShip";
    const table = "MomSysProductBackShip";
    const view = "MomSysVwProductBackShip";

    const [ , setNotification ] = useRecoilState(notificationState);
    const [ grid, setGrid ] = useState();
    const [ refresh, setRefresh ] = useState();
    const [ onSelect, setOnSelect ] = useState({});
    const [ isEmpty, setIsEmpty ] = useState();
    const [ schema, setSchema ] = useState();

    useEffect(() => {
        let mounted = true;

        (async () => {
            const schema = await getSchema(table);

            if (mounted) {
                setSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems.length > 0) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect({});
                        }
                    },10)
                );

                grid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems.length > 0) {
                            setOnSelect(grid.selectedItems[0]);
                        } else {
                            setOnSelect({});
                        }
                    },10)
                );
            }

        })();

    }, [grid]);

    const onNotifySuccess = (msg) => {

        setNotification({

          severity: "success",
          msg: msg,
          open: true,

        });
    };

    const onNotifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onInitialized = ( grid ) => {

        grid.selectionMode = "Row";

        setGrid ( grid );

    }

    const onApiInitialized = (api) => {

        setRefresh (api)

    }

    const doHandleBackendErrorMsg = (reason) => {
        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response?.data} `;

    }

    const onLoadData = ( data ) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    const onAppendButtonInFrontOfSearch = () => {

        return (
            <>
                <CrudScreenActionItemLayoutByAccessType accessType = {accessType}>
                    <MomSysProductBackShipButton
                        id = "MomSysProductBackShipButton"
                        createButtonName = {"productBackShip"}
                        schema = {schema}
                        refresh = {refresh}
                        onSuccess = {onNotifySuccess}
                        onFailure = {onNotifyFailure}
                        customizeErrorMsgCallback = {doHandleBackendErrorMsg}
                    />
                    <MomSysBackShipCancelButton
                        id={"productBackShipCancel"}
                        createButtonName = {"backShipCancel"}
                        color = "secondary"
                        schema = {schema}
                        grid = {grid}
                        disabled={isEmpty || onSelect.canceled}
                        refresh={refresh}
                        onSuccess={onNotifySuccess}
                        onFailure={onNotifyFailure}
                    />
                </CrudScreenActionItemLayoutByAccessType>
            </>
        )

    }

    return (
        <BasicCrudScreenType 
            id={id} 
            table={table} 
            view={view} 
            headerTitle = {t("term.productBackShipHistory")}
            noCreate 
            noEdit 
            noDelete 
            noExcel 
            onLoadData={onLoadData}
            onInitialized = {onInitialized}
            onApiInitialized = {onApiInitialized}
            onAppendButtonInFrontOfSearch = {onAppendButtonInFrontOfSearch}
        />
    )

}

export default MomSysProductBackShip;
