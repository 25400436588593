import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import ActionButtonLayout from "../layout/ActionButtonLayout";
import BasicTabScreenType from "../screenTypes/functional/BasicTabScreenType";

function EguDefectiveStatustemp(props) {

    const { tab } = props;
    
    const id = "EguDefectiveStatus";

    const lotProcessFailViewName = "MomSysVwLotProcessFail";
    const inputInspectionFailViewName = "EguVwInputInspectionFail";
    const selfInspectionFailViewName = "EguVwSelfInspectionFail";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <div style={{ height: height }}>
            {tab === lotProcessFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + tab}
                    view={tab}
                    headerTitle={`term.${tab}`}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {tab === inputInspectionFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + tab}
                    view={tab}
                    headerTitle={`term.${tab}`}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}

            {tab === selfInspectionFailViewName ? (
                <BasicCrudScreenType
                    id={id + "-" + tab}
                    view={tab}
                    headerTitle={`term.${tab}`}
                    noCreate
                    noEdit
                    noDelete
                    noExcel
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}
        </div>
    );
}

function EguDefectiveStatus(props) {

    const [tab, setTab] = useState("MomSysVwLotProcessFail");

    const onTabChanged = (tab) => {
        setTab(tab);
    };

    return (
        <BasicTabScreenType
            id="EguDefectiveStatus"
            tabs={["MomSysVwLotProcessFail","EguVwInputInspectionFail","EguVwSelfInspectionFail"]}
            tabLabels={[`term.MomSysVwLotProcessFail`,`term.EguVwInputInspectionFail`,`term.EguVwSelfInspectionFail`]}
            tabsStyle={{
                left: 0,
                margin: "0px",
                padding: "0px",
                height: "48px",
                width: "1200px"
            }}
            onTabChanged={onTabChanged}
            value={tab}
            selectedTabStyle={
                {
                    borderTop: "0px solid rgb(128, 128, 128)",
                    borderLeft: "0px solid rgb(128, 128, 128)",
                    borderRight: "0px solid rgb(128, 128, 128)",
                    borderRadius: "10px 10px 0px 0px",
                    margin: "0px",
                    marginTop: "2px",
                    width: "200px",
                    minHeight: "42px",
                    opacity: 1,
                    backgroundColor: "#7280ce"
                } 
            }
            notSelectedTabStyle={
                {
                    margin: "0px",
                    marginTop: "2px",
                    padding: "0px",
                    width: "200px",
                    minHeight: "42px",
                } 
            }
        >
            <EguDefectiveStatustemp tab={tab}/>
        </BasicTabScreenType>
    )
}

export default EguDefectiveStatus;
