/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function OrderPurchaseDetailTarget(props) {
  const { onSelect, setOrderPurchaseDetailSelect } = props;

  const { t } = useTranslation();
  const [grid, setGrid] = useState();

  const [, setDetailApi] = useState();

  useEffect(() => {
    (async () => {
      if (grid) {
        grid.selectionChanged.addHandler((grid, event) => {
          if (grid.selectedItems && grid.selectedItems[0]) {
            setOrderPurchaseDetailSelect(grid.selectedItems[0]);
          } else {
            setOrderPurchaseDetailSelect([]);
          }
        });

        grid.itemsSourceChanged.addHandler((grid, event) => {
          if (grid.selectedItems && grid.selectedItems[0]) {
            setOrderPurchaseDetailSelect(grid.selectedItems[0]);
          } else {
            setOrderPurchaseDetailSelect([]);
          }
        });
      }
    })();
  }, [grid]);

  useEffect(() => {
    setDetailApi((detailApi) => {
      if (detailApi) {
        detailApi.refresh();
      }

      return detailApi;
    });
  }, [onSelect?.orderPurchaseId]);

  const onModifyViewSchema = (schema) => {
    for (let column of schema.columns) {
      switch (column.name) {
        case "materialCode":
        case "materialCodeVer":
        case "qty":
        case "materialName":
        case "price":
        case "unitCost":
          break;

        case "qtyInput":
          column.displayName = "receivedQty";

          break;

        default:
          column.grid.hide = true;
      }
    }
  };

  const onModifyUrl = (url, orderPurchaseId) => {
    return url + "/" + orderPurchaseId;
  };

  const onInitialized = (grid) => {
    grid.selectionMode = "Row";
    setGrid(grid);
  };

  const onApiInitialized = (api) => {
    setDetailApi(api);
  };

  return (
    <BasicCrudScreenType
      id="OrderPurchaseDetailTarget"
      headerTitle={t("term.poDetail")}
      view="EguVwOrderPurchaseDetail"
      noCreate
      noEdit
      noDelete
      noExcel
      noFilter
      noSearch
      noPagination
      onModifyViewSchema={onModifyViewSchema}
      onModifyViewUrl={(url) => onModifyUrl(url, onSelect?.orderPurchaseId)}
      onInitialized={onInitialized}
      onApiInitialized={onApiInitialized}
    />
  );
}

export default OrderPurchaseDetailTarget;
