import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import notificationState from "../../states/notificationState";
import "./gridEvent.css"
import apiClient from "../../lib/common/apiClient";
import AutoForm from "../form/functional/AutoForm";
import defineConf from "../../config/defineConf";
import Button from "../input/Button";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    root: {
        fontSize: "1.1rem",
        borderRadius: 0,
        border: "solid 1px",
        float: "right",
        marginRight: "2px"
    }
}));

function InspectionResultPopView(props) {
    const { id, selectMstGrid, selectGroupItemGrid, mstApi, inspectionType } = props;

    const [schema, setSchema] = useState();
    const [onSelectGrid, setOnSelectGrid] = useState();
    const [formData, setFormData] = useState();
    const classes = useStyle();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(()=> {
        console.log(selectGroupItemGrid);
        setFormData({
            inspectionItemId: selectGroupItemGrid.inspectionItemId, 
            inspectionItemName: selectGroupItemGrid.inspectionItemName,
        });

        const schema = {
            name: "FailedLot",
            type: "BASE TABLE",
            searchAll: false,
            columns: [
                {
                    name: "inspectionItemId",
                    type: "int(11)",
                    formOrder: 10,
                    disabled: true,
                    hideInForm: true,
                    formItemType: "text",
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "inspectionItemName",
                    type: "varchar(255)",
                    formOrder: 20,
                    disabled: true,
                    formItemType: "text",
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "inspectionCriteriaId",
                    type: "int(11)",
                    formOrder: 30,
                    disabled: true,
                    hideInForm: true,
                    formItemType: "text",
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "inspectionCriteriaName",
                    type: "varchar(255)",
                    formOrder: 40,
                    disabled: true,
                    formItemType: "text",
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "determinant",
                    type: "varchar(255)",
                    formOrder: 50,
                    required: true,
                    formItemType: "text",
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "qcResult",
                    type: "int(4)",
                    formOrder: 60,
                    formItemType: "select",
                    selectItems: [
                        { name: "selectItems.qcResult.OK", value: defineConf.qcResult.QC_OK },
                        { name: "selectItems.qcResult.NOK", value: defineConf.qcResult.QC_NOK }
                    ],
                    defaultValue: defineConf.qcResult.QC_OK,
                    visible: true,
                    width: "48%",
                    validation: false,
                },
                {
                    name: "description",
                    type: "varchar(255)",
                    formOrder: 70,
                    formItemType: "text",
                    visible: true,
                    width: "100%",
                    validation: false,
                },
                {
                    name: "inspectionDate",
                    type: "datetime(6)",
                    formOrder: 80,
                    formItemType: "datetime",
                    // defaultValue: new Date(),
                    visible: true,
                    width: "100%",
                    validation: false,
                }
            ]
        };
        setSchema(schema);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        setFormData({...selectGroupItemGrid, determinant: selectGroupItemGrid.determinant});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectGroupItemGrid]);

    useEffect(() => {
        (async () => {
            if (onSelectGrid) {
                onSelectGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });

                onSelectGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectGrid(grid.selectedItems[0]);
                    } else {
                        setOnSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSelectGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onChangeForm = async ( changeFormData ) => {
        setFormData({...changeFormData });
    };

    const addSelfInspection = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        // build base api url
        let url = "/api/MomSysInspectionRslt/selfInspection";
        
        let qcData = 1;
        let qcResult = formData.qcResult;
        if(qcResult === null || qcResult === "null" || qcResult === undefined || qcResult === 0) {
            qcData = 0;
        }


        let apiData = { 
            inspectionType: inspectionType,
            targetLotNo: selectMstGrid.lotNo,
            materialCode: selectMstGrid.materialCode,
            materialCodeVer: selectMstGrid.materialCodeVer,
            inspectionGroupId: formData.inspectionGroupId,
            qcResult: qcData,
            defectiveCode: defineConf.defectiveCode.INS_OK,
            inspectionItemId: formData.inspectionItemId,
            inspectionCriteriaId: formData.inspectionCriteriaId,
            determinant: formData.determinant,
            inspectionDate: formData.inspectionDate,
            description: formData.description,
        };

        let form = new FormData ();
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(apiData));
        
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }
    
    const onApiSuccess = (result) => {
        if (onSuccess) {
            onSuccess (t("success.insertSuccess", {table: "MomSysMaterialByCompany"}));
        }

        setFormData ((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        if (onSuccess) {
            setTimeout (onSuccess(result.data), 0);
        }

        mstApi.refresh();
    };

    const onApiFailure = (reason) => {
        notifyFailure (t(reason.response.data));
    };

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning (msg);
                return false;
            }
        }
        return true;
    }

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const deleteSelfInspection = (schema, formData) => {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        // build base api url
        let url = "/api/MomSysInspectionRslt/selfInspectionDelete";
        url += `/${selectGroupItemGrid.inspectionId}`;
        url += `/${formData.inspectionItemId}`;
        url += `/${formData.inspectionCriteriaId}`;

        //autoFormDialog columnMap 생성부분 
        if (schema) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        };
        
        apiClient
            .del(url)
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    return (
        <>
            <div>
                <AutoForm
                    id={id}
                    initialData={formData}
                    schema={schema}
                    mode="edit"
                    onChange={onChangeForm}
                />
            </div>
            <hr />
            <div>
                <Button 
                    id="inspectionResultDelete" 
                    color="secondary" 
                    className={classes.root}
                    onClick={() => deleteSelfInspection(schema, formData)}
                    disabled={Object.keys(selectGroupItemGrid).length === 0 ? true : false}
                >
                    {t("buttonName.delete")}
                </Button>
                <Button 
                    id="inspectionResultUpdate" 
                    color="primary" 
                    className={classes.root}
                    onClick={() => addSelfInspection(schema, formData)}
                    disabled={Object.keys(selectGroupItemGrid).length === 0 ? true : false}
                >
                    {t("buttonName.update")}
                </Button>
            </div>
        </>
    );
}

export default InspectionResultPopView;
