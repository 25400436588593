import { useEffect, useState } from "react";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

function VwSubMenuItems (props){

    const view = "VwSubMenuItems";

    const { onSearchInitialized, apiInitialized, onInitialized, onLoadData} = props;

    const [ api, setApi ] = useState ( null );

    useEffect ( () => {

        if (api ) {

            api.refresh ();

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ api ] );

    const onApiInitialized = ( api ) => {

        if(apiInitialized) {
            apiInitialized(api)
        } 

        setApi ( api );

    };

    const onGridInitialized = ( grid ) => {
        grid.selectionMode = "Row";
        onInitialized(grid)
    };

    return (
    
        <BasicCrudScreenType
            id="DailyProductInOutReport"
            noHeader
            view={view}
            onApiInitialized={onApiInitialized}
            onSearchInitialized={onSearchInitialized}
            onInitialized={onGridInitialized}
            onLoadData = {onLoadData}
        />

    );

}
export default VwSubMenuItems;