import React from "react";
import BasicCrudScreenActionItemLayout from "./BasicCrudScreenActionItemLayout";
import PopCrudScreenActionItemLayout from "./PopCrudScreenActionItemLayout";

function CrudScreenActionItemLayoutByAccessType ( props ) {

    const { accessType, children } = props;

    if( accessType === "pop" ) {
        return (
            <PopCrudScreenActionItemLayout>
                { children }
            </PopCrudScreenActionItemLayout>
        )
    }

    return (
        <BasicCrudScreenActionItemLayout>
            { children }
        </BasicCrudScreenActionItemLayout>
    )

}

export default CrudScreenActionItemLayoutByAccessType;

