import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { debounce } from "lodash";

import AccessControl from "../functional/AccessControl";

import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import notificationState from "../../states/notificationState";

import getSchema from "../../lib/api/getSchema";

import layoutConf from "../../config/layoutConf";
import MomSysShipRecordCancelButton from "../input/MomSysShipRecordCancelButton";

function MomSysShipRecord() {

    const [tableSchema, setTableSchema] = useState();

    const [height, setHeight] = useState();
    const [grid, setGrid] = useState();
    const [api, setApi] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const [lotApi, setLotApi] = useState();

    const [onSelectMst, setOnSelectMst] = useState();


    const [, setNotification] = useRecoilState(notificationState);

    const { t } = useTranslation();

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 96);

    };

    useEffect(() => {

        let mounted = true;

        (async () => {

            window.addEventListener("resize", handleResize);

            handleResize();

            let schema = await getSchema("MomSysShipRecord");

            if (mounted) {
                setTableSchema(schema);

            }

        })();

        return (() => {
            mounted = false;
            window.removeEventListener("resize", handleResize);
        });

    }, []);

    useEffect(() => {

        (async () => {

            if (grid) {

                grid.selectionChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);

                    }
                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0]);
                    }
                }, 10));
            }
        })();

    }, [grid]);

    useEffect(() => {
        if (lotApi) {
            lotApi.refresh()
        }
    }, [onSelectMst?.transactionGroupId, lotApi])

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

        if (data.length < 1) {
            setOnSelectMst(null)
        }

    };

    const onInitialized = (grid) => {

        setGrid(grid);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onLotApiInitialized = (api) => {

        setLotApi(api);

    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    // const notifyWarning = (msg) => {
    //     setNotification({
    //         severity: "warning",
    //         msg: msg,
    //         open: true,
    //     });
    // };

    // const onNotifyWarning = (msg) => {
    //     notifyWarning(msg);
    // };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
        if (lotApi) {
            lotApi.refresh();
        }
    };

    const onAppendButtonInFrontOfSearch = () => {
        return (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl
                        accessTarget={tableSchema && tableSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysShipRecordCancelButton
                            id="MomSysShipRecord"
                            schema={tableSchema}
                            color="primary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            refresh={api}
                            grid={grid}
                            disabled={isEmpty}
                        />
                    </AccessControl>

                </BasicCrudScreenActionItemLayout>
            </>
        );
    };

    const onModifyViewUrl = (url) => {
        console.log(url + '/' + onSelectMst?.transactionGroupId)
        return url + '/' + onSelectMst?.transactionGroupId;

    }


    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.5}>
                    <BasicCrudScreenType
                        id="MomSysShipRecord"
                        table="MomSysShipRecord"
                        view="MomSysVwShipRecord"
                        headerTitle={t("term.standardLogShip")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onLoadData={onLoadData}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    <BasicCrudScreenType
                        id="MomSysVwShipRecordLot"
                        table="MomSysVwShipRecordLot"
                        view="MomSysVwShipRecordLot"
                        headerTitle={t("term.standardProductInfo")}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onModifyViewUrl={onModifyViewUrl}
                        // onLoadData={onLoadData}
                        // onInitialized={onInitialized}
                        onApiInitialized={onLotApiInitialized}
                        exportFormatItem={() => { }}
                    />
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default MomSysShipRecord;
