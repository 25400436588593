import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as wjcCore from '@grapecity/wijmo';
import initLocalization from "./lib/common/initLocalization";
import 'mutation-events';

wjcCore.setLicenseKey("radixmrm.com,137439412643163#B0s7Jby5GepRWYy9yd7dHL8EjLzITMugjNx8iM9EDLt36YuM7dh96b0FWbh9SZ4VHct36YuITL4NXYlhGdy3mbtAXYucDOtATMy4iNz4yMtIzYlJiOiMXbEJCLi8yYulEIoNWZ4FWayFkI0ISYONkIsIyM6EzM4YjMxQTOzQzNzEjI0ICZJJCL355W0IyZsZmIsIiM6FjMwIjI0IiclZnIsU6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3cnMLF6MBBFRhF5cJJXcrFkaroGWtt4VTRUVRpWUSVFdl3yT0ZDc4MmN4kzcPV7LQV6V7JFWUhXMPp6SFZnaGN7NPJEVvFmc6glUycmTJtybjZjdMNFeNBXO5I7Uy46Q8dkVmpUODlTQUVXT9E6blVXUBtWcvM4SvMGRJZ7K0lTMrIXOGl6KNhVUkdnchBXdNtUTIdmarQmSaJkMDVTcyIzNNVEU9E6Myp6Vw3mYVx4LjpEZvZUY5g4azZFcax6N4NkbFRFVV34QYJGRxRlMlhTcnRmVFVjYZp7V6t4dBR5LudzZphlZ9x4YsFDOiJHWxlHVjV4QFlGTuljVBxkbJFVYrY4RT5EbkFUUR5kVPFDcywkcL3WaKdUTTNWRMNmYlVnRhxmMIdTaJFWW9kkWnJFMzlUcU36bClXNBx4SMlzZ5RlS9pVardWYNRzMXdlevRlS5gGdCJiOiMlIsICRDRkRBV4NiojIIJCLyQDM5gDM9EzM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUDMyMzNwACNxATMzIDMyIiOiQncDJCLigDNuMTNuYTMuIzNxwSbvNmLtJXb8lGZhJHLt36Yu46bthXakFmcuQGbv5GLt36Yu46bthXakFmcuYXZkxyb49CdjVmbu36YrNWa5FnLzIjNwk6boNmbppWesIjMuEjL8YTMuITOxwSMx8CMyIjLwAjMuATMsIjMuMTNuYTMuIzNxwSM78yMx8CMwIjLwEDLt36Yu46bthXakFmcuQXYtVWes46bj9Sbv5GepRWYyxSbvNmLt3Wb8lGZhJnL7d7dscDOuATMy8iNz8yMs46bvNmLt3Wb8lGZhJnL7d7dsETMugjNx8CO6EjLykTMsQ7cvhGbhN6bsxSbvNmLt3Wb8lGZhJnLwBXY32Sb");

( async () => {

    await initLocalization();
    
} ) ();

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
