/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicStaticGridScreenTypeWithoutFooter from "../screenTypes/functional/BasicStaticGridScreenTypeWithoutFooter";
import apiClient from "../../lib/common/apiClient";
import ToolbarFlexSpacer from "../layout/ToolbarFlexSpacer";
import DeleteRecordButtonLayout from "../layout/DeleteRecordButtonLayout";
import { debounce } from "lodash";

function OrderPurchaseDetailInputList(props) {
  const { onInitialized } = props;

  const [schema, setSchema] = useState();

  const [grid, setGrid] = useState();

  const { t } = useTranslation();

  const [select, setSelect] = useState();

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let mounted = true;

    (async () => {
      let result = await apiClient.get("/api/OrderPurchaseInputDetailTempSchema");
      if (mounted) {
        setSchema(result.data);
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (grid) {
        grid.selectionChanged.addHandler(
          debounce((grid, event) => {
            if (grid.selectedItems && grid.selectedItems[0]) {
              setSelect(grid.selectedItems[0]);
              setDisabled(false);
            } else {
              setSelect([]);
              setDisabled(true);
            }
          }, 10)
        );

        grid.itemsSourceChanged.addHandler(
          debounce((grid, event) => {
            if (grid.selectedItems && grid.selectedItems[0]) {
              setSelect(grid.selectedItems[0]);
              setDisabled(false);
            } else {
              setSelect([]);
              setDisabled(true);
            }
          }, 10)
        );
      }
    })();
  }, [grid]);

  const onInputListInitialized = (grid) => {
    setGrid(grid);

    if (onInitialized) {
      onInitialized(grid);
    }
  };

  const handleClick = (grid, select) => {
    for (let row of grid.itemsSource.items) {
      if (
        select.materialCode === row.materialCode &&
        select.materialCodeVer === row.materialCodeVer
      ) {
        grid.itemsSource.remove(select);
      }
    }
  };
  return (
    <BasicStaticGridScreenTypeWithoutFooter
      id="OrderPurchaseDetailTarget"
      embededInOtherScreenType
      headerTitle={t("term.inputList")}
      headerTitleStyle={{
        fontSize: "16px",
        fontWeight: "bold",
        marginLeft: "8px",
        marginTop: "8px",
      }}
      onInitialized={onInputListInitialized}
      schema={schema}
      onAppendButton={() => {
        return (
          <>
            <ToolbarFlexSpacer xs />
            <DeleteRecordButtonLayout
              id={"test"}
              color="secondary"
              onClick={() => handleClick(grid, select)}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
}

export default OrderPurchaseDetailInputList;
