/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Snackbar, Divider } from "@material-ui/core";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import { Alert } from "@material-ui/lab";
import { debounce } from "lodash";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";

function MomSysShipOfOrderBuyerDialog(props) {
    const {
        id,
        schema,
        moveable,
        resizable,
        fullWidth,
        maxWidth,

        initialData,

        onClose,
        onInitialized,
        onSuccess,

        ...others
    } = props;

    const { t } = useTranslation();

    const [dialogInstance, setDialogInstance] = useState();
    const [formData, setFormData] = useState({});

    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [responseOkButtonDisabled, setresponseOkButtonDisabled] = useState(false);
    
    const [possibleShipmentGrid, setPossibleShipmentGrid] = useState();
    const [possibleShipmentSelect, setPossibleShipmentSelect] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const checkItemValidataion = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                notifyWarning(msg);
                return false;
            }
        }

        return true;
    };
    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    useEffect(() => {
        if (possibleShipmentGrid) {
            (async () => {
                possibleShipmentGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipmentSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipmentSelect();
                        }
                    },10)
                );

                possibleShipmentGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setPossibleShipmentSelect(grid.selectedItems[0]);
                        } else {
                            setPossibleShipmentSelect();
                        }
                    },10)
                );
            })();
        }
    }, [possibleShipmentGrid]);

    useEffect(() => {
        if (possibleShipmentSelect) {
            (async () => {
                setFormData((formData) => {
                    let newFormData = {
                        ...formData,

                        shipId: "ship", // for code generate
                        shipmentNo: "shipment",
                        shipOfOrderBuyer: true,
                        
                        shipDueDate: new Date(),
                        shipmentOrderDate : new Date(),

                        orderBuyerId: possibleShipmentSelect.orderBuyerId,
                        orderBuyerName: possibleShipmentSelect.orderBuyerName,
                        companyName: possibleShipmentSelect.companyName,
                        dueDate: possibleShipmentSelect.dueDate,

                        shipDate: new Date(),
                    };

                    return newFormData;
                });
            })();
        }
    }, [possibleShipmentSelect]);

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {

        setresponseOkButtonDisabled(false);

        if (onSuccess) {
            setTimeout(() => onSuccess(result.data), 0);
        }

        dialogInstance.hide();
    };

    const onApiFailure = (reason) => {

        setresponseOkButtonDisabled(false);

        notifyFailure(reason.response ? reason.response.data : JSON.stringify(reason));
    };

    const onOk = (schema, formData) => {

        setresponseOkButtonDisabled(true);

        if (!checkItemValidataion(schema, formData)) {
            console.error("Form Validation Failed");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                notifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        // build base api url

        const url = `/api/${schema.name}`;

        let apiData = { ...formData };
        let form = new FormData();

        let json = {};
        let deleted = {};

        if (schema && !schema.columnMap) {
            let columnMap = {};
            schema.columns.map((column) => (columnMap[column.name] = column));
            schema.columnMap = columnMap;
          }
          
        for (const formItem in apiData) {
            if (schema.columnMap && schema.columnMap[formItem] && schema.columnMap[formItem].formItemType === "file") {
                deleted[formItem] = apiData[formItem].deletedFiles;

                for (const file of apiData[formItem].activeFiles) {
                    if (file.type === "url") continue;

                    form.append(formItem, file.file);
                }
            } else {
                if (apiData[formItem] !== undefined) {
                    json[formItem] = apiData[formItem];
                }
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        apiClient
            .post(url, form, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    };

    const onCancel = () => {
        dialogInstance.hide();

        setPossibleShipmentSelect();

        setFormData();
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);
    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }

        setPossibleShipmentSelect();

        setFormData({shipId: "ship"});
    };

    const onLoadData = (data) => {
        setOkButtonDisabled(data.length < 1 ? true : false);
    };

    const modifyFormDataCallback = ( newFormData, name, value ) => {
        if(name === "shipDate"){
            newFormData.shipmentOrderDate = value;
            newFormData.shipDueDate = value
        }
    }

    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>{t("dialog.title.MomSysShip.ship")}</DialogTitle>
            </DialogHeaderWithClose>

            <Divider />

            <DialogContent style={{ padding: "0px", height: "350px" }}>
                <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.565}>
                    <BasicCrudScreenType
                                id="MomSysVwPossibleShipment"
                                embededInOtherScreenType
                                headerTitle={t("tableName.MomSysUglOrderBuyerMst")}
                                view="MomSysVwShipOfOrderBuyerTarget"
                                onLoadData={onLoadData}
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={(grid) => {
                                    grid.selectionMode = "Row";
                                    setPossibleShipmentGrid(grid);
                                }}
                            />
                        {/* <BasicCrudScreenType
                            id={"MomSysVwPossibleShipment"}
                            view={"MomSysVwPossibleShipment"}
                            headerTitle={t("term.shipment")}
                            noExcel
                            onInitialized={(grid) => {
                                grid.selectionMode = "Row";
                                setPossibleShipmentGrid(grid);
                            }}
                            onLoadData={onLoadData}
                            embededInOtherScreenType
                        /> */}
                    </ReflexElement>

                    <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                    <ReflexElement flex={0.435}>
                        <div
                            style={{
                                padding: "16px",
                            }}
                        >
                            <AutoForm
                                id="ship-form"
                                schema={schema}
                                mode="edit"
                                initialData={formData}
                                onChange={onChangeFormData}
                                modifyFormDataCallback={modifyFormDataCallback}
                            />
                        </div>
                    </ReflexElement>
                </ReflexContainer>

                <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
                >
                    <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                        {notification.msg}
                    </Alert>
                </Snackbar>
            </DialogContent>

            <Divider />

            <DialogActionsOkCancel
                labels={[t("common.add")]}
                buttonDisabled={[(okButtonDisabled || responseOkButtonDisabled), false]} /* [okButtonDisabled, cancelButtonDisabled] */
                onOk={() => onOk(schema, formData)}
                onCancel={onCancel}
            />
        </Dialog>
    );
}

export default MomSysShipOfOrderBuyerDialog;
