/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import notificationState from "../../../states/notificationState";
import apiClient from "../../../lib/common/apiClient";

function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    
    return year + month + day + hours + minutes + seconds;
}

// Form Item Type Select에 대응하는 Custom DataGridColumn
function DataGridFilesDownloadColumn ( props ) {

    const { t } = useTranslation();

    const { schema, ...others } = props;

    const [, setNotification] = useRecoilState(notificationState);
    const [ cellTemplate, setCellTemplate ] = useState ( ( ctx ) => {
        return <></>;
    } );

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = ( reason ) => {

        notifyFailure(
            t ( "success.fileDownloadFailure", { reason } )
        );

    };
    
    const onClick = ( ctx ) => {

        let url;

        let pathArray = JSON.parse(ctx.item[schema.name])
        
        if ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' ) {

            url = `/multipleFiles/${JSON.stringify(pathArray)}`;
            // url = pathArray;

        } else {

            url = `http://localhost:8080/multipleFiles/${JSON.stringify(pathArray)}`;
        }

        apiClient
            .get ( url, { responseType: "blob" } )
            .then ( ( result ) => {

                const blob = new Blob ( [result.data] );
                const link = document.createElement ( "a" );

                link.href = window.URL.createObjectURL ( blob );
                link.download = `${getCurrentDateTime()}_files.zip`;
                link.click ();

                window.URL.revokeObjectURL ( link.href );

            } )
            .catch ( async ( reason ) => {
                const blob = reason.response.data
                const msg = await blob.text ();
                onApiFailure ( msg )
            } )


    };

    useEffect ( () => {

        setCellTemplate ( () => ( ( ctx ) => {
        
            let pathArray = JSON.parse(ctx.item[schema.name])

            if ( pathArray && pathArray.length > 0) {
                return (
                    <>

                        <span onClick={ () => onClick ( ctx )}>
                                                        
                            <IconButton size="small" sx={{p: 0, m: 0}}>

                                <Icon>download</Icon>

                            </IconButton>

                        </span>

                    </>
                );

            }

        } ) );

    }, [schema] );

    return (

        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridFilesDownloadColumn;

