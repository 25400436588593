import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authProfileState from "../../states/authProfileState";
import { useRecoilValue } from "recoil";
import getSchema from "../../lib/api/getSchema";
import getSpecific from "../../lib/api/getSpecific";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { CellType } from "@grapecity/wijmo.grid";
import AccessControl from "../functional/AccessControl";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import CreateRecordButton from "../input/CreateRecordButton";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import ApprovalButton from "../input/ApprovalButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import EguUglOrderBuyerDetail from "./EguUglOrderBuyerDetail";
import { debounce, cloneDeep } from "lodash";
import defineConf from "../../config/defineConf";
import DeliveryStateChangeButton from "../input/DeliveryStateChangeButton";

function EguUglOrderBuyerMst(props) {
    const id = "EguUglOrderBuyerMst";
    const table = "MomSysUglOrderBuyerMst";
    const view = "EguVwOrderBuyerMst";

    const [height, setHeight] = useState(0);

    const [eguUglOrderBuyerMstSchema, setEguUglOrderBuyerMstSchema] = useState();
    const [eguUglOrderBuyerMstUpdateSchema, setEguUglOrderBuyerMstUpdateSchema] = useState();
    const [eguUglOrderBuyerMstGrid, setEguUglOrderBuyerMstGrid] = useState();
    const [eguUglOrderBuyerMstApi, setEguUglOrderBuyerMstApi] = useState();
    const [eguUglOrderBuyerMstData, setEguUglOrderBuyerMstData] = useState();
    const [eguUglOrderBuyerMstDataIsEmpty, setEguUglOrderBuyerMstDataIsEmpty] = useState();
    const [eguUglOrderBuyerDetailDataIsEmpty, setEguUglOrderBuyerDetailDataIsEmpty] = useState();
    const [eguUglOrderBuyerMstSelect, setEguUglOrderBuyerMstSelect] = useState();

    const authProfile = useRecoilValue(authProfileState);

    const { t } = useTranslation();

    const [approvalCtx, setApprovalCtx] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);

            if (mounted) {
                for (const column of schema.columns) {
                    switch (column.name) {
                        case "plCode":
                        case "downPayment":
                        case "middlePayment":
                            column.hideInForm = true;

                            break;

                        default:
                            break;
                    }
                }

                setEguUglOrderBuyerMstSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    useEffect(() => {
        if (eguUglOrderBuyerMstSchema) {
            const updateSchema = cloneDeep(eguUglOrderBuyerMstSchema);

            if (eguUglOrderBuyerMstSelect?.approvalState) {
                for (const column of updateSchema.columns) {
                    switch (column.name) {
                        case "dueDate":
                        case "factoryShipDate":
                        case "cutOff":
                        case "ETDBusan":
                            break;

                        default:
                            column.readonly = true;
                            break;
                    }
                }
            } else {
            }

            setEguUglOrderBuyerMstUpdateSchema(updateSchema);
        }
    }, [eguUglOrderBuyerMstSchema, eguUglOrderBuyerMstSelect]);

    useEffect(() => {
        let approvalCtx = {
            approvalTypeId: 6, // ApprovalType.approvalTypeId
            tableName: table,
            statusColumn: "approved",
            schema: eguUglOrderBuyerMstSchema,
            onValidateApprovalItem: (item) => {
                if (item.createdBy !== authProfile.username) {
                    return [false, t("error.approvalRequesterNotMatch")];
                }

                return [true, ""];
            },
            makeRequestItemDescription: (item) => {
                return `${t("approvalRequestMsg.msg.so", { soName: item.plCode, soNumber: item.orderBuyerId })}`; // Use Translation on Production Code
            },
        };

        setApprovalCtx(approvalCtx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eguUglOrderBuyerMstSchema]);

    useEffect(() => {
        (async () => {
            if (eguUglOrderBuyerMstGrid) {
                eguUglOrderBuyerMstGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerMstSelect([]);
                        }
                    },10)
                );

                eguUglOrderBuyerMstGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setEguUglOrderBuyerMstSelect(grid.selectedItems[0]);
                        } else {
                            setEguUglOrderBuyerMstSelect([]);
                        }
                    },10)
                );
                eguUglOrderBuyerMstGrid.loadedRows.addHandler(
                    debounce((flex, event) => {
                        for (let row of flex.rows) {
                            if (row.dataItem.deliveryState !== defineConf.deliveryState.COMPLETE_DELIVERY) {
                                if (new Date(row.dataItem.dueDate) <= new Date()) {
                                    row.cssClass = `failed-stock-apply`;
                                } else {
                                    row.cssClass = null;
                                }
                            }
                        }
                    },10)
                );
            }
        })();
    }, [eguUglOrderBuyerMstGrid]);

    const exportFormatItem = (args) => {
        let { panel, col, row, xlsxCell } = args;

        if (eguUglOrderBuyerMstData && panel.cellType === CellType.Cell) {
            switch (panel.columns[col].binding) {
                case "approvalState":
                    switch (eguUglOrderBuyerMstData[row][panel.columns[col].binding]) {
                        case defineConf.approvalState.PENDING:
                            xlsxCell.value = t("selectItems.approvalState.approvalPending");

                            break;

                        case defineConf.approvalState.PARTIALLY_APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved");

                            break;

                        case defineConf.approvalState.APPROVED:
                            xlsxCell.value = t("selectItems.approvalState.totallyApproved");

                            break;

                        case defineConf.approvalState.REJECTED:
                            xlsxCell.value = t("selectItems.approvalState.approvalRejected");

                            break;

                        case defineConf.approvalState.WITHDRAWN:
                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn");

                            break;

                        case defineConf.approvalState.CANCELLED:
                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled");

                            break;

                        default:
                            break;
                    }

                    break;

                default:
                    break;
            }
        }
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onUpdateMaster = () => {
        if (eguUglOrderBuyerMstGrid) {
            const updateTarget = eguUglOrderBuyerMstGrid.selectedItems[0];

            (async () => {
                const buyerMst = await getSpecific(view, updateTarget.orderBuyerId);

                updateTarget.cost = buyerMst.cost;
                eguUglOrderBuyerMstGrid.refresh(false); // grid.refresh(false) : grid.content only, grid에 즉시 반영
            })();
        }
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement data-cy="OrderBuyerMstPart" flex={0.6}>
                    <BasicCrudScreenType
                        id={id}
                        headerTitle={t("term.so")}
                        table={table}
                        view={view}
                        onInitialized={(grid) => {
                            setEguUglOrderBuyerMstGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setEguUglOrderBuyerMstApi(api);
                        }}
                        onLoadData={(data) => {
                            setEguUglOrderBuyerMstData(data);
                            setEguUglOrderBuyerMstDataIsEmpty(data.length < 1 ? true : false);
                        }}
                        exportFormatItem={exportFormatItem}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <BasicCrudScreenActionItemLayout>
                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod="POST"
                                >
                                    <CreateRecordButton
                                        id={id}
                                        schema={eguUglOrderBuyerMstSchema}
                                        refresh={eguUglOrderBuyerMstApi}
                                        setInitialDataCallback={(formData) => {
                                            if ("orderBuyerDate" in formData) {
                                                formData.orderBuyerDate = new Date();
                                            }
                                        }}
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod={
                                        eguUglOrderBuyerMstSchema &&
                                        (eguUglOrderBuyerMstSchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <UpdateRecordButton
                                        id={id}
                                        schema={eguUglOrderBuyerMstUpdateSchema}
                                        grid={eguUglOrderBuyerMstGrid}
                                        refresh={eguUglOrderBuyerMstApi}
                                        disabled={
                                            //eguUglOrderBuyerMstSelect?.approvalStates ||
                                            eguUglOrderBuyerMstDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod="DELETE"
                                >
                                    <DeleteRecordButton
                                        id={id}
                                        schema={eguUglOrderBuyerMstSchema}
                                        grid={eguUglOrderBuyerMstGrid}
                                        refresh={eguUglOrderBuyerMstApi}
                                        color="secondary"
                                        disabled={
                                            eguUglOrderBuyerMstSelect?.approvalState || eguUglOrderBuyerMstDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                        onPartialFailure={onNotifyWarning}
                                    />
                                </AccessControl>
                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod={
                                        eguUglOrderBuyerMstSchema &&
                                        (eguUglOrderBuyerMstSchema.updateWithPatch ? "PATCH" : "PUT")
                                    }
                                >
                                    <DeliveryStateChangeButton
                                        id={id}
                                        schema={eguUglOrderBuyerMstSchema}
                                        grid={eguUglOrderBuyerMstGrid}
                                        refresh={eguUglOrderBuyerMstApi}
                                        disabled={defineConf.approvalState.APPROVED !== eguUglOrderBuyerMstSelect?.approvalState}
                                        onFailure={onFailure}
                                        onSuccess={onSuccess}

                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod="APPROVAL"
                                >
                                    <ApprovalButton
                                        id={id}
                                        grid={eguUglOrderBuyerMstGrid}
                                        refresh={eguUglOrderBuyerMstApi}
                                        approvalCtx={approvalCtx}
                                        disabled={
                                            (eguUglOrderBuyerMstSelect?.orderBuyerState !==
                                            defineConf.orderBuyerState.ENROLL
                                                ? true
                                                : false) ||
                                            eguUglOrderBuyerMstDataIsEmpty ||
                                            eguUglOrderBuyerDetailDataIsEmpty
                                        }
                                        onSuccess={onSuccess}
                                        onFailure={onFailure}
                                    />
                                </AccessControl>

                                <AccessControl
                                    accessTarget={eguUglOrderBuyerMstSchema && eguUglOrderBuyerMstSchema.name}
                                    accessMethod="SAVETOEXCEL"
                                >
                                    <SaveToExcelButton
                                        id={id}
                                        view={view}
                                        grid={eguUglOrderBuyerMstGrid}
                                        color="primary"
                                        exportFormatItem={exportFormatItem}
                                        disabled={eguUglOrderBuyerMstDataIsEmpty}
                                    />
                                </AccessControl>
                            </BasicCrudScreenActionItemLayout>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement data-cy="OrderBuyerDetailPart" flex={0.4}>
                    {eguUglOrderBuyerMstSelect ? (
                        <EguUglOrderBuyerDetail
                            onSelectMst={eguUglOrderBuyerMstSelect}
                            onUpdateMaster={onUpdateMaster}
                            onDetailEmpty={(isEmpty) => {
                                setEguUglOrderBuyerDetailDataIsEmpty(isEmpty);
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguUglOrderBuyerMst;
