import React, { useEffect, useState } from "react";
import defineConf from "../../config/defineConf";
import { useRecoilState } from "recoil";
import notificationState from "../../states/notificationState";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import DeleteSingleRecordButton from "../input/DeleteSingleRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import AccessControl from "../functional/AccessControl";
import getSchema from "../../lib/api/getSchema";
import MomSysCustomerSupplyReturnRegisterButton from "../input/MomSysCustomerSupplyReturnRegisterButton";
import MomSysCustomerSupplyMaterialReturnButton from "../input/MomSysCustomerSupplyMaterialReturnButton";
import MomSysCustomerSupplyReturnCancelButton from "../input/MomSysCustomerSupplyReturnCancelButton";

function MomSysCustomerSupplyReturn(props) {
    const id = "MomSysCustomerSupplyReturn";
    const table = "MomSysCustomerSupplyReturn";
    const view = "MomSysVwCustomerSupplyReturn";

    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [CustomerSupplyReturnSchema, setCustomerSupplyReturnSchema] = useState();
    const [CustomerSupplyReturnGrid, setCustomerSupplyReturnGrid] = useState();
    const [CustomerSupplyReturnApi, setCustomerSupplyReturnApi] = useState();
    const [CustomerSupplyReturnDataIsEmpty, setCustomerSupplyReturnDataIsEmpty] = useState();
    const [CustomerSupplyReturnSelect, setCustomerSupplyReturnSelect] = useState();

    const [, setNotification] = useRecoilState(notificationState);

    useEffect(() => {
        let mounted = true;

        (async () => {
            let schema = await getSchema(table);
            if (mounted) {
                setCustomerSupplyReturnSchema(schema);
            }
        })();

        return () => {
            mounted = false;
        };
    }, []);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;

        (async () => {
            if (CustomerSupplyReturnGrid && mounted) {
                CustomerSupplyReturnGrid.selectionChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyReturnSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyReturnSelect();
                        }
                    }, 10)
                );

                CustomerSupplyReturnGrid.itemsSourceChanged.addHandler(
                    debounce((grid, event) => {
                        if (grid.selectedItems && grid.selectedItems[0]) {
                            setCustomerSupplyReturnSelect(grid.selectedItems[0]);
                        } else {
                            setCustomerSupplyReturnSelect();
                        }
                    }),
                    10
                );
            }
        })();

        return () => {
            mounted = false;
        };
    }, [CustomerSupplyReturnGrid]);

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const customizeErrorMsgCallback = (reason) => {
        onFailure(reason);
    }
    return (
        <div style={{ height: height }}>
            <BasicCrudScreenType
                id={id}
                view={view}
                headerTitle={t("term.MomSysCustomerSupplyReturn")}
                onInitialized={(grid) => {
                    grid.selectionMode = "Row";
                    setCustomerSupplyReturnGrid(grid);
                }}
                onApiInitialized={(api) => {
                    setCustomerSupplyReturnApi(api);
                }}
                onLoadData={(data) => {
                    setCustomerSupplyReturnDataIsEmpty(data.length < 1 ? true : false);
                }}
                onAppendButtonInFrontOfSearch={() => (
                    <BasicCrudScreenActionItemLayout>

                        <AccessControl
                            accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                            accessMethod="POST"
                        >
                            <MomSysCustomerSupplyReturnRegisterButton
                                id={id}
                                schema={CustomerSupplyReturnSchema}
                                refresh={CustomerSupplyReturnApi}
                                onSuccess={onSuccess}
                                customizeErrorMsgCallback={customizeErrorMsgCallback}
                            />
                        </AccessControl>

                        <AccessControl
                        accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                        accessMethod="POST"
                    >
                        <MomSysCustomerSupplyReturnCancelButton
                            id="MomSysShipRecord"
                            schema={CustomerSupplyReturnSchema}
                            color="secondary"
                            createButtonName="create"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            refresh={CustomerSupplyReturnApi}
                            grid={CustomerSupplyReturnGrid}
                            disabled={CustomerSupplyReturnDataIsEmpty}
                        />
                    </AccessControl>

                        {/* <AccessControl
                            accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                            accessMethod={
                                CustomerSupplyReturnSchema &&
                                (CustomerSupplyReturnSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <UpdateRecordButton
                                id={id}
                                schema={CustomerSupplyReturnSchema}
                                grid={CustomerSupplyReturnGrid}
                                refresh={CustomerSupplyReturnApi}
                                disabled={CustomerSupplyReturnDataIsEmpty || CustomerSupplyReturnSelect?.returnDate}
                                modifyFormDataCallback={(formData) => {
                                    if (formData.qcResult === defineConf.qcResult.QC_OK) {
                                        formData.defectiveCode = "INS000";
                                    }
                                }}
                                onSuccess={onSuccess}
                            />
                        </AccessControl> */}

                        {/* <AccessControl
                            accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                            accessMethod="DELETE"
                        >
                            <DeleteSingleRecordButton
                                id={id}
                                schema={CustomerSupplyReturnSchema}
                                grid={CustomerSupplyReturnGrid}
                                refresh={CustomerSupplyReturnApi}
                                makeItemName={(row) =>
                                    `${t("columnName.CustomerSupplyReturnId")}: ${row.customerSupplyReturnId}`
                                }
                                disabled={CustomerSupplyReturnDataIsEmpty || CustomerSupplyReturnSelect?.returnDate}
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                color="secondary"
                            />
                        </AccessControl> */}

                        {/* <AccessControl
                            accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                            accessMethod={
                                CustomerSupplyReturnSchema &&
                                (CustomerSupplyReturnSchema.updateWithPatch ? "PATCH" : "PUT")
                            }
                        >
                            <MomSysCustomerSupplyMaterialReturnButton
                                id={`${id}-return-button`}
                                schema={CustomerSupplyReturnSchema}
                                grid={CustomerSupplyReturnGrid}
                                refresh={CustomerSupplyReturnApi}
                                disabled={
                                    !CustomerSupplyReturnSelect ||
                                    CustomerSupplyReturnSelect?.returnDate ||
                                    CustomerSupplyReturnSelect?.wareHouseName
                                }
                                onSuccess={onSuccess}
                                customizeErrorMsgCallback={customizeErrorMsgCallback}
                            />
                        </AccessControl> */}

                        <AccessControl
                            accessTarget={CustomerSupplyReturnSchema && CustomerSupplyReturnSchema.name}
                            accessMethod="SAVETOEXCEL"
                        >
                            <SaveToExcelButton
                                id={id}
                                view={view}
                                grid={CustomerSupplyReturnGrid}
                                color="primary"
                                exportFormatItem={() => { }}
                                disabled={CustomerSupplyReturnDataIsEmpty}
                            />
                        </AccessControl>

                    </BasicCrudScreenActionItemLayout>
                )}
                noCreate
                noEdit
                noDelete
                noExcel
                
            />
        </div>
    );
}

export default MomSysCustomerSupplyReturn;
