import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import getSchema from "../../lib/api/getSchema";
import AccessControl from "../functional/AccessControl";
import DeleteRecordButton from "../input/DeleteRecordButton";
import notificationState from "../../states/notificationState";
import PopProductShipLotCreateButton from "../input/PopProductShipLotCreateButton";
import PopProductShipLotUpdateButton from "../input/PopProductShipLotUpdateButton";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";

function PopProductShipLotTemp(props) {
    const id = "PopProductShipLotTemp";

    const { shipmentSelect, mstGridEmpty } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [lotGrid, setLotGrid] = useState();
    const [isEmpty, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysShipDetailLotTemp");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        if(api) {
            api.refresh();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentSelect]);
    
    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setLotGrid(grid.selectedItems[0]);
                    } else {
                        setLotGrid([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setLotGrid(grid.selectedItems[0]);
                    } else {
                        setLotGrid([]);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };
    
    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    const onModifyUrl = (url) => {
        return url + '/' + shipmentSelect.shipmentId + '/' + shipmentSelect.materialCode + '/' + shipmentSelect.materialCodeVer;
    }

    const onLoadData = (data) => {
        let isEmpty = data.length < 1 ? true : false;
        setIsEmpty(isEmpty);
    };

    return (
        <BasicCrudScreenType 
            id={id}
            table="MomSysShipDetailLotTemp"
            view="PopVwProductShipLot"
            headerTitle={t("term.popProductShipLot")}
            onApiInitialized={onApiInitialized}
            onInitialized={onInitialized}
            onModifyViewUrl={(url) => onModifyUrl(url)}
            onLoadData={onLoadData}
            noCreate
            noEdit
            noDelete
            noExcel
            onAppendButtonInFrontOfSearch={() => (
                <>
                    <PopCrudScreenActionItemLayout>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <PopProductShipLotCreateButton 
                                id={id}
                                grid={grid}
                                schema={schema}
                                refresh={api}
                                shipmentSelect={shipmentSelect}
                                onSuccess={onSuccess}
                                disabled={mstGridEmpty}
                            />
                        </AccessControl>
                        
                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                            <PopProductShipLotUpdateButton
                                id={id}
                                schema={schema}
                                lotGrid={lotGrid}
                                refresh={api}
                                shipmentSelect={shipmentSelect}
                                onSuccess={onSuccess}
                                disabled={isEmpty}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id="PopProductShipLot"
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={isEmpty}
                            />
                        </AccessControl>
                    </PopCrudScreenActionItemLayout>
                </>
            )}
        />
    );
}
export default PopProductShipLotTemp;
