
function parseS3FileName(data) {


    const dataArr = data.split("-");
    // const fileNameArr = dataArr[dataArr.length - 1];
    
    let fileName = ''
    for (let i = 7; i < dataArr.length ; i++) {
        if (i === 7) fileName = dataArr[i]
        else fileName += '-' + dataArr[i]
    }

    return decodeURIComponent(fileName);

}

export default parseS3FileName;