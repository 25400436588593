/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import {useDropzone} from "react-dropzone";
import { FormControl, FormHelperText, InputLabel, ImageList, ImageListItem, ImageListItemBar, IconButton, Paper, makeStyles } from "@material-ui/core";
import Icon from "../../presentation/Icon";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import getUploadedFileOriginalName from "../../../lib/utils/getUploadedFileOriginalName";

const useStyles = makeStyles((theme) => ({

        container: {
            marginTop: "16px",
        },
        image: {
            border: "solid 0.5px gray",
        },
        imageList: {
            width: "100%",
        },
        imageListItem: {
            height: "auto",
        },
        fileName: {
            border: "solid 0.5px gray",
            height: "100%"
        },
        fileNameText: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            textAlign: "center",
            transform: "translate(-50%,-50%)",
            fontSize: "large"
        },
        closeIcon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },

}));

function getFileExtension ( file ) {

    let extension = ( ( file && file.split(".")[file.split(".").length - 1] ) || file );

    return extension;

}

function urlToFileName ( url ) {

    if ( !url ) {
        return "";
    }

    let fileName = url.substring(url.lastIndexOf('/')+1);

    return fileName;

}

function isFileImage ( file ) {

    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
 
    return file && acceptedImageTypes.includes(file['type']);

};

function isFileTypeAllowed ( file, allowedFileTypes ) {

    return file && allowedFileTypes.includes(file['type']);

}

function isUrlImage ( url ) {

    const acceptedImageTypes = ['gif', 'jpeg', 'jpg', 'png']

    if ( !url ) {

        return false;

    }

    let fileName = urlToFileName ( url );
    let extension = getFileExtension ( fileName );

    return url && acceptedImageTypes.includes(extension.toLowerCase ());

}

function FormItemFile ( props ) {
    
    const { t } = useTranslation ();

    const { id, className, column, value, error, helperText, InputLabelProps, onChange, ...others } = props;

    const classes = useStyles ();

    const [ files, setFiles ] = useState(value?value:{
        deletedFiles: [],
        activeFiles: []
    });

    const onDrop = useCallback (( acceptedFiles ) => {
        
        setFiles ( ( files ) => {

            let newFiles = files ? cloneDeep(files) : {
                deletedFiles: [],
                activeFiles: []
            };

            for ( const file of acceptedFiles ) {

                if ( column.maxFiles > 0 && newFiles.activeFiles.length === column.maxFiles ) {
                    alert ( t("infoMsg.noMoreFileAllowed") );
                    break;
                }

                if ( column.allowedFileTypes && ! isFileTypeAllowed ( file, column.allowedFileTypes ) ) {
                    alert ( t("error.notAllowedFileType") );
                    break;
                }

                if ( newFiles.activeFiles.find ( ( prev ) => {

                    if ( prev.type === "url" ) {

                        if ( getUploadedFileOriginalName ( urlToFileName(prev.url) ).localeCompare( file.path ) === 0 ) {

                            return true;
                        
                        }

                    }

                    if ( prev.type === "file" ) {

                        if ( prev.file.path === file.path ) {

                            return true;

                        }

                    }
                    
                    return false;

                } ) ) continue;

                newFiles.activeFiles.push ( {
                    type: "file",
                    file: file 
                });

            }
            
            if ( onChange ) {

                onChange ( newFiles );

            }

            return newFiles;

        } );

    }, []);

    const { getRootProps, getInputProps} = useDropzone({onDrop});
    const { ...rootProps } = getRootProps ();

    const onRemoveFile = ( event, index ) => {

        setFiles ( ( files ) => {
            
            let newFiles = files ? cloneDeep(files) : {
                deletedFiles: [],
                activeFiles: []
            };

            if ( newFiles.activeFiles[index].type === "url" ) {

                newFiles.deletedFiles.push ( newFiles.activeFiles[index].url );

            }
            
            newFiles.activeFiles = [...(newFiles.activeFiles.slice (0, index)), ...(newFiles.activeFiles.slice(index+1, newFiles.activeFiles.length))];
            
            if ( onChange ) {

                onChange ( newFiles );

            }
            
            return newFiles;

        })

        event.stopPropagation ();

    }

    return (
        <FormControl error={error} required={column.required} style={{width: column.width, height : column.height}} {...others} >
            <InputLabel 
                id={ id + "-label"}
                shrink={true}
                {...InputLabelProps}
            >
                { t(`columnName.${column.displayName||column.name}`) }
            </InputLabel>
            <Paper
                variant="outlined"
                style={{
                    height: ( ( column && column.height ) ? column.height : 250 ),
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                    overflow: 'hidden',
                    color: "primary",
                    backgroundImage: ( column.maxFiles > 0 && files.activeFiles.length === column.maxFiles ) ? `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1'><text x='50%' y='50%' fill='gray' font-size='16' text-anchor='middle'>${t("infoMsg.noMoreFileAllowed")}</text></svg>")` : `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1'><text x='50%' y='50%' fill='gray' font-size='16' text-anchor='middle'>${t("infoMsg.dragAndDropFileOrClickHere")}</text></svg>")`,
                    marginTop: "16px",
                    marginBottom: "16px",
                    padding: "16px",

                }}
                {...rootProps}
            >
                {
                    <ImageList cols={ ( column && column.fileUploadPanelColCount ) || 4 } className={classes.imageList} style={{ height: ( ( column && column.height ) ? column.height : 250 ) - 32 }}>
                    {
                        files.activeFiles.map ( ( activeFile, index ) =>
                            <ImageListItem key={activeFile.type === "url" ? activeFile.url : activeFile.file.name } className={classes.imageListItem}>
                                {
                                    activeFile.type === "url"
                                    ?
                                        isUrlImage ( urlToFileName ( activeFile.url ) )
                                        ?
                                        <img alt={ urlToFileName ( activeFile.url ) } className={classes.image} src={( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging' )? activeFile.url : "http://localhost:8080" + activeFile.url } />
                                        :
                                        <div className={classes.fileName}>
                                            <p className={classes.fileNameText}>{ getFileExtension ( urlToFileName ( activeFile.url ) ).toUpperCase() }</p>
                                        </div>
                                    :
                                    (
                                        isFileImage ( activeFile.file )
                                        ?
                                        <img alt={activeFile.file.name} className={classes.image} src={URL.createObjectURL(activeFile.file)} />
                                        :
                                        <div className={classes.fileName}>
                                            <p className={classes.fileNameText}>{ getFileExtension ( activeFile.file.name ).toUpperCase( ) }</p>
                                        </div>
                                    )
                                }
                                <ImageListItemBar
                                    subtitle={activeFile.type==="url" ? getUploadedFileOriginalName(urlToFileName(activeFile.url)) : activeFile.file.name}
                                    position="top"
                                    actionIcon={
                                        <IconButton
                                            className={classes.closeIcon}
                                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                            onClick={( event ) => onRemoveFile ( event, index )}
                                        >
                                            <Icon>close</Icon>
                                        </IconButton>
                                    }
                                    actionPosition="right"
                                />
                            </ImageListItem>
                        )
                    }
                    </ImageList>
                }
            </Paper>
            <input {...getInputProps()} />
            <FormHelperText>{ helperText }</FormHelperText>
        </FormControl>
    );

}

export default FormItemFile;
