import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { useSetRecoilState } from "recoil";

import layoutConf from "../../config/layoutConf";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import PopProductShipLotView from "./PopProductShipLotView";
import PopProductShipLotTemp from "./PopProductShipLotTemp";

import notificationState from "../../states/notificationState";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import getSchema from "../../lib/api/getSchema";
import PopProductShip from "../input/PopProductShip";

function EguProductShip(props) {
    const { t } = useTranslation();

    const [height, setHeight] = useState(0);

    const [shipmentGrid, setShipmentGrid] = useState();
    const [shipmentApi, setShipmentApi] = useState();
    const [shipmentSelect, setShipmentSelect] = useState();
    const [mstGridEmpty, setMstGridEmpty] = useState();
    const [schema, setSchema] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("PopVwProductShipment");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (shipmentGrid) {
                shipmentGrid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setShipmentSelect(grid.selectedItems[0]);
                        setMstGridEmpty(false);
                    } else {
                        setShipmentSelect([]);
                        setMstGridEmpty(true);
                    };
                });

                shipmentGrid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setShipmentSelect(grid.selectedItems[0]);
                        setMstGridEmpty(false);
                    } else {
                        setShipmentSelect([]);
                        setMstGridEmpty(true);
                    };
                });
            }
        })();
    }, [shipmentGrid, shipmentSelect]);

    const onApiInitialized = (api) => {
        setShipmentApi(api);
    };
    
    const onInitialized = (grid) => {
        setShipmentGrid(grid);
    };
    
    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    return (
        <div style={{ height: height }}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement flex={0.6} data-cy="Master">
                    <BasicCrudScreenType 
                        id="PopProductShip"
                        table="PopVwProductShipment"
                        view="PopVwProductShipment"
                        headerTitle={t("term.shipment")}
                        onApiInitialized={onApiInitialized}
                        onInitialized={onInitialized}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onAppendButtonInFrontOfSearch={() => (
                            <>
                                <PopCrudScreenActionItemLayout>
                                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                        <PopProductShip
                                            id="PopProductShipCreate"
                                            schema={schema}
                                            api={shipmentApi}
                                            shipmentSelect={shipmentSelect}
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            createButtonName={"ship"}
                                            disabled={mstGridEmpty}
                                        />
                                    </AccessControl>
                                </PopCrudScreenActionItemLayout>
                            </>
                        )}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement>
                    {shipmentSelect ? (
                        <ReflexContainer orientation="vertical">
                            <ReflexElement flex={0.5} data-cy="DetailMaterial">
                                <PopProductShipLotView shipmentSelect={shipmentSelect}/>
                            </ReflexElement>

                            <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                            <ReflexElement flex={0.5} data-cy="DetailLot">
                                <PopProductShipLotTemp
                                    shipmentSelect={shipmentSelect}
                                    mstGridEmpty={mstGridEmpty}
                                />
                            </ReflexElement>
                        </ReflexContainer>
                    ) : (
                        <></>
                    )}
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default EguProductShip;
